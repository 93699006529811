.Genre {
  &__Carousel {
    font-weight: 300;
    font-size: 2.25rem;
  }
  
  &__MoreInfo {
    margin: .3rem 0;
  }
  
  &__Work {
    margin: .3rem 0;
    font-size: 1.6rem;
  }
}