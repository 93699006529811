.Dot {
  width: 2rem;
  height: 2rem;
  shape-outside: circle(50%);
  border: 2px solid #000;
  border-radius: 50%;
  background-color: #111;
  cursor: pointer;

  &_active {
    cursor: default;
    background-color: #ccc;
  }
}