@import '../../../../styles/variables';

.Subtitle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
  &__MoreInfo {
    white-space: nowrap;
    font-size: 5rem;
    font-weight: 300;
    
    @media only screen and (max-width: 25em) {
      white-space: initial;
      text-align: center;
    }

    &::before, 
    &::after {
      display: block;
      content: '';
      border: 0;
      height: 1.15px;
      width: 100%; 
    }

    &::before {
      margin-right: 2rem;
      background-image: linear-gradient(to left, #fff, $primary-gray, $primary-dark-gray, transparent);
    }
    
    &::after {
      margin-left: 2rem;
      background-image: linear-gradient(to right, #fff, $primary-gray, $primary-dark-gray, transparent);
    }
  }

  &__Profile {
    display: block;
    font-size: 2rem;
    font-weight: 300;
  }
 
  &__Work {
    font-size: 2.25rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }
}
  