.Name {
  &__Staff {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 2rem;
    font-weight: 300;
    margin: 1rem 0;
    height: 2em;
  }

  &__Review {
    display: block;
    font-size: 2.5rem;
    margin-bottom: .75rem;
  }
}