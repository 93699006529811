.Overview {
  line-height: 1.4;
  position: relative;
  cursor: pointer;

  &_less {
    cursor: pointer;
  }

  &__Work {
    cursor: initial;
    font-size: 1.7rem;
    line-height: 1.3;
  }

  &__Arrow {
    display: block;
    width: 1.9rem;
    height: 1.9rem;
    margin: 1rem auto .25rem;
    cursor: pointer;
    transform: rotateZ(180deg);
    transition: all 200ms ease-out;

    @media only screen and (max-width: 37.5em) {
      margin: 1.5rem auto;
      width: 2.5rem;
      height: 2.5rem;
    }

    &:hover {
      transform: rotateZ(180deg) scale(1.05);
      filter: brightness(1.5);
    }

    &_less {
      transform: rotateZ(0);

      &:hover {
        transform: rotateZ(0) scale(1.05);
        filter: brightness(1.5);
      }
    }
  }
}