@import '../../../../styles/variables';

.Button {
  display: inline-block;
  border: none;
  padding: .5rem 2rem;
  margin-bottom: .5rem;
  font-size: $normal-font-size;
  font-family: inherit;
  background-color: transparent;
  color: inherit;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}