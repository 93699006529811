.Header {
  position: relative;
  display: flex;
  height: 75vh;
  width: 100%;
  overflow: hidden; }
  @media only screen and (max-width: 68.75em) {
    .Header {
      flex-direction: column;
      height: 100vh; } }
  .Header__SideDrawer {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 25%;
    width: 50rem;
    -webkit-transition: all 75ms ease-out;
    transition: all 75ms ease-out;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    opacity: 1; }
    @media only screen and (max-width: 68.75em) {
      .Header__SideDrawer {
        flex-direction: row;
        width: 100%;
        max-width: 100%;
        height: 50%;
        -webkit-transition: all 100ms ease-out;
        transition: all 100ms ease-out; } }
    @media only screen and (max-width: 43.75em) {
      .Header__SideDrawer {
        height: 40%; } }
    @media only screen and (max-width: 37.5em) {
      .Header__SideDrawer {
        flex-direction: column;
        height: 90%; } }
    .Header__SideDrawer_shrink {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
      width: 0;
      visibility: hidden;
      opacity: 0; }
      @media only screen and (max-width: 68.75em) {
        .Header__SideDrawer_shrink {
          -webkit-transform: translateX(0) translateY(-100%);
                  transform: translateX(0) translateY(-100%);
          width: 100%;
          height: 0; } }
  .Header:hover .Header__SideDrawerToggle {
    visibility: visible;
    opacity: 1; }
  .Header__Share {
    display: block;
    position: absolute;
    z-index: 200;
    right: 1rem;
    top: 5.5rem;
    width: 2.5rem;
    height: 2.5rem;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
    cursor: pointer; }
    .Header__Share:hover {
      -webkit-filter: brightness(1.3);
              filter: brightness(1.3); }
  .Header__SideDrawerToggle {
    position: absolute;
    z-index: 100;
    right: 1rem;
    top: 1rem;
    visibility: hidden;
    opacity: 0;
    background-color: #797474;
    -webkit-transition: all 300ms ease-in-out 200ms, background-color 300ms ease-in-out, -webkit-transform 400ms ease-out;
    transition: all 300ms ease-in-out 200ms, background-color 300ms ease-in-out, -webkit-transform 400ms ease-out;
    transition: all 300ms ease-in-out 200ms, background-color 300ms ease-in-out, transform 400ms ease-out;
    transition: all 300ms ease-in-out 200ms, background-color 300ms ease-in-out, transform 400ms ease-out, -webkit-transform 400ms ease-out;
    cursor: pointer; }
    @media only screen and (max-width: 68.75em) {
      .Header__SideDrawerToggle {
        visibility: visible;
        opacity: 1;
        width: 3.5rem;
        height: 3.5rem;
        top: auto;
        top: initial;
        right: auto;
        right: initial;
        bottom: .5rem;
        left: 1rem; } }
    @media only screen and (max-width: 37.5em) {
      .Header__SideDrawerToggle {
        width: 5rem;
        height: 5rem; } }
