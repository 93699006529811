.Message {
  position: relative;
  display: block;
  padding: 2rem 10rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
  box-shadow: -1.25rem 1.5rem 3.5rem rgba(0, 0, 0, .3);
  width: 45vw;

  @media only screen and (max-width: 50em) {
    width: 75vw;
    padding: 2rem 5rem;
  }

  @media only screen and (max-width: 31.25em) {
    width: 85vw;
  }
  
  &__Text {
    font-size: 1.8rem;
    font-weight: 500;
    text-align: center;
    width: max-content;
    margin: 0 auto;
    
    @media only screen and (max-width: 31.25em) {
      width: auto;
    }
  }

  &_success {
    background-color: #69f0ae;
    color: #1b5e20;
  }

  &_warning {
    background-color: #fff59d;
    color: #f57f17;
  }
  
  &_danger {
    background-color: #ef9a9a;
    color: #b71c1c;
  }

  &__Close {
    display: block;
    width: 1.8rem;
    height: 1.8rem;
    position: absolute;
    top: 1.2rem;
    left: 1.3rem;
    filter: invert(.2);
    transition: all 200ms ease-out;
    cursor: pointer;

    &:hover {
      filter: invert(.1);
      transform: scale(1.1);
    }
  }
}

.msg {    
  &_appear {
    opacity: 0;
    transition: all 300ms ease-out;
  }

  &_appear_active {
    opacity: 1;
    transition: all 300ms ease-out;
  }
  
  &_enter_done {
    opacity: 1;
    transition: all 300ms ease-out;
  }

  &_exit {
    opacity: 1;
    transition: all 300ms ease-out;
  }

  &_exit_active {
    opacity: 1;
    transition: all 300ms ease-out;
  }

  &_exit_done {
    opacity: 0;
    transition: all 300ms ease-out;
  }
}