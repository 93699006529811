.Youtube_Youtube__286qt {
  display: block;
  width: 100%;
  height: 100%;
  border-bottom: 5px solid black;
  background-color: #000; }

.SideDrawerToggle_SideDrawerToggle__2TgIY {
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  -webkit-clip-path: circle(50%);
          clip-path: circle(50%); }
  @media only screen and (max-width: 68.75em) {
    .SideDrawerToggle_SideDrawerToggle__2TgIY {
      -webkit-transform: rotateZ(-90deg);
              transform: rotateZ(-90deg); } }
  .SideDrawerToggle_SideDrawerToggle__2TgIY:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
    @media only screen and (max-width: 68.75em) {
      .SideDrawerToggle_SideDrawerToggle__2TgIY:hover {
        -webkit-transform: scale(1.05) rotateZ(-90deg);
                transform: scale(1.05) rotateZ(-90deg); } }
  .SideDrawerToggle_SideDrawerToggle_shrink__2updt {
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg); }
    @media only screen and (max-width: 68.75em) {
      .SideDrawerToggle_SideDrawerToggle_shrink__2updt {
        -webkit-transform: rotateZ(90deg) translate(0.5rem, 50%);
                transform: rotateZ(90deg) translate(0.5rem, 50%);
        left: 50% !important; } }
    .SideDrawerToggle_SideDrawerToggle_shrink__2updt:hover {
      -webkit-transform: scale(1.05) rotateZ(180deg);
              transform: scale(1.05) rotateZ(180deg); }
      @media only screen and (max-width: 68.75em) {
        .SideDrawerToggle_SideDrawerToggle_shrink__2updt:hover {
          -webkit-transform: scale(1.05) rotateZ(90deg) translate(0.5rem, 50%);
                  transform: scale(1.05) rotateZ(90deg) translate(0.5rem, 50%); } }

.VideoType_VideoType__1EmEv {
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 300; }

.FilmImage_FilmImage__Carousel__tqo3n {
  display: block;
  object-fit: cover;
  object-position: 50% 35%;
  height: 100%;
  width: 100%;
  -webkit-transition: all 200ms ease-out;
  transition: all 200ms ease-out; }

.FilmImage_FilmImage__Thumbnail__1O9MI {
  display: block;
  height: 100%;
  width: 100%; }
  @media only screen and (max-width: 37.5em) {
    .FilmImage_FilmImage__Thumbnail__1O9MI {
      width: 15rem;
      height: auto;
      margin: 0 auto; } }

.FilmImage_FilmImage__Avatar__2uDPL {
  display: block;
  background-color: #556080;
  height: 10rem;
  width: 10rem;
  padding: .5rem;
  border-radius: 50%;
  margin: 0 auto; }

.FilmImage_FilmImage__Person__3LKYq {
  display: block;
  border-radius: 2rem;
  box-shadow: -1rem 1rem 3em rgba(0, 0, 0, 0.5); }

.VideoState_VideoState__3Tac2 {
  display: block;
  width: 2.5rem;
  height: 2.5rem; }

.VideoList_VideoList__1aIfm {
  overflow-x: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  color: #ccc;
  border-bottom: 3px solid black;
  border-left: 3px solid black;
  width: 100%;
  height: 100%; }
  @media only screen and (max-width: 68.75em) {
    .VideoList_VideoList__1aIfm {
      display: grid;
      grid-template-columns: repeat(auto-fit, 25rem);
      justify-content: center;
      align-items: center; } }
  @media only screen and (max-width: 37.5em) {
    .VideoList_VideoList__1aIfm {
      overflow-y: auto;
      height: 50%; } }
  .VideoList_VideoList__Item__2hV1A {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    -webkit-transition: all 150ms ease-in;
    transition: all 150ms ease-in;
    padding: .75rem 1.25rem; }
    @media only screen and (max-width: 68.75em) {
      .VideoList_VideoList__Item__2hV1A {
        flex-direction: column;
        height: auto;
        width: auto; } }
    .VideoList_VideoList__Item_active__5hqcA {
      cursor: auto;
      cursor: initial;
      background-color: #797474;
      color: rgba(0, 0, 0, 0.8); }
    .VideoList_VideoList__Item__2hV1A:hover:not(.VideoList_VideoList__Item_active__5hqcA) {
      background-color: rgba(255, 255, 255, 0.2); }
  .VideoList_VideoList__Img__yXkO5 {
    position: relative;
    height: 100%;
    width: 100%; }
    @media only screen and (max-width: 68.75em) {
      .VideoList_VideoList__Img__yXkO5 {
        width: 75%;
        height: auto; } }
  .VideoList_VideoList__Overlay__3j8Mv {
    display: block;
    position: relative;
    width: 100%;
    height: 100%; }
    .VideoList_VideoList__Overlay__3j8Mv::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: -webkit-linear-gradient(top left, rgba(0, 0, 0, 0) 5%, rgba(0, 0, 0, 0.55) 95%);
      background-image: linear-gradient(to bottom right, rgba(0, 0, 0, 0) 5%, rgba(0, 0, 0, 0.55) 95%); }
  .VideoList_VideoList__State__MHUve {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .VideoList_VideoList__Type__3lDm5 {
    display: inline-block;
    height: 100%;
    width: 100%;
    text-align: center; }

.Tagline_Tagline__3hUce {
  font-size: 2.25rem;
  font-weight: 300;
  margin-top: 1rem; }
  @media only screen and (max-width: 68.75em) {
    .Tagline_Tagline__3hUce {
      font-size: 2.75rem; } }

.Overview_Overview__1kVMZ {
  line-height: 1.4;
  position: relative;
  cursor: pointer; }
  .Overview_Overview_less__2iPbt {
    cursor: pointer; }
  .Overview_Overview__Work__3iYvr {
    cursor: auto;
    cursor: initial;
    font-size: 1.7rem;
    line-height: 1.3; }
  .Overview_Overview__Arrow__1kdyz {
    display: block;
    width: 1.9rem;
    height: 1.9rem;
    margin: 1rem auto .25rem;
    cursor: pointer;
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
    -webkit-transition: all 200ms ease-out;
    transition: all 200ms ease-out; }
    @media only screen and (max-width: 37.5em) {
      .Overview_Overview__Arrow__1kdyz {
        margin: 1.5rem auto;
        width: 2.5rem;
        height: 2.5rem; } }
    .Overview_Overview__Arrow__1kdyz:hover {
      -webkit-transform: rotateZ(180deg) scale(1.05);
              transform: rotateZ(180deg) scale(1.05);
      -webkit-filter: brightness(1.5);
              filter: brightness(1.5); }
    .Overview_Overview__Arrow_less__3zpux {
      -webkit-transform: rotateZ(0);
              transform: rotateZ(0); }
      .Overview_Overview__Arrow_less__3zpux:hover {
        -webkit-transform: rotateZ(0) scale(1.05);
                transform: rotateZ(0) scale(1.05);
        -webkit-filter: brightness(1.5);
                filter: brightness(1.5); }

.Time_Time__2_8hm {
  line-height: 1.5; }
  .Time_Time__Work__3M7YA {
    font-size: 1.7rem; }

.Website_Website__X2oPv {
  font-size: 3rem;
  font-weight: 300;
  width: 100%;
  padding-bottom: .75rem;
  border-bottom: 1px solid #797474; }
  @media only screen and (max-width: 68.75em) {
    .Website_Website__X2oPv {
      font-size: 3.5rem; } }
  .Website_Website__Name__14kBr, .Website_Website__Name__14kBr:link, .Website_Website__Name__14kBr:visited {
    display: inline-block;
    text-decoration: none;
    color: inherit;
    -webkit-transition: all 200ms ease-out;
    transition: all 200ms ease-out;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
  .Website_Website__Name__14kBr:hover {
    -webkit-transform: translateY(-0.1rem);
            transform: translateY(-0.1rem);
    color: #797474; }
  .Website_Website__Name__14kBr:active {
    -webkit-transform: translateY(0.1rem);
            transform: translateY(0.1rem); }

.Genre_Genre__Carousel__Ys1eW {
  font-weight: 300;
  font-size: 2.25rem; }

.Genre_Genre__MoreInfo__3xEqM {
  margin: .3rem 0; }

.Genre_Genre__Work__2KBmb {
  margin: .3rem 0;
  font-size: 1.6rem; }

.Hearts_Hearts__IldyH {
  display: flex; }
  .Hearts_Hearts__IldyH > *:not(:last-child) {
    margin-right: .2rem; }
  .Hearts_Hearts__Carousel__1epub {
    display: block;
    width: 2rem;
    height: 2rem; }
  .Hearts_Hearts__Thumbnail__1BMbI {
    display: block;
    width: 1.5rem;
    height: 1.5rem; }
  .Hearts_Hearts__Work__gsAfL {
    display: block;
    width: 1.75rem;
    height: 1.75rem;
    margin-bottom: .5rem; }
  .Hearts_Hearts__RateFilm__f92Kk {
    display: block;
    width: 2rem;
    height: 2rem; }

.RateFilm_RateFilm__2NveM {
  display: inline-block;
  position: relative;
  margin: 1rem 0; }
  .RateFilm_RateFilm__2NveM:hover .RateFilm_RateFilm__AveRating__i86CZ {
    visibility: 0;
    opacity: 0; }
  .RateFilm_RateFilm__2NveM:hover .RateFilm_RateFilm__UserRating__3HLTS {
    visibility: visible;
    opacity: 1; }
  .RateFilm_RateFilm__UserRating__3HLTS {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0; }
  .RateFilm_RateFilm__HeartOverlap__35rLj {
    position: relative;
    width: 2.2rem;
    height: 2rem; }
  .RateFilm_RateFilm__UserHeart__Qe31t {
    cursor: pointer;
    height: 2rem;
    width: 2rem; }

.ClickImage_ClickImage__2i_jK {
  display: block;
  cursor: pointer; }
  .ClickImage_ClickImage__ArrowRound__3_Jql {
    width: 5rem !important;
    height: 5rem !important;
    cursor: pointer !important; }
    @media only screen and (max-width: 56.25em) {
      .ClickImage_ClickImage__ArrowRound__3_Jql {
        width: 6rem !important;
        height: 6rem !important; } }
    @media only screen and (max-width: 46.88em) {
      .ClickImage_ClickImage__ArrowRound__3_Jql {
        width: 6.5rem !important;
        height: 6.5rem !important; } }
    @media only screen and (max-width: 40.625em) {
      .ClickImage_ClickImage__ArrowRound__3_Jql {
        width: 5.5rem !important;
        height: 5.5rem !important; } }
  .ClickImage_ClickImage__GoBack__2V_Eq {
    position: fixed;
    top: 3rem;
    left: 3rem;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    -webkit-filter: invert(0.7) brightness(2);
            filter: invert(0.7) brightness(2);
    z-index: 1000;
    -webkit-transition: all 200ms ease-out;
    transition: all 200ms ease-out; }
    .ClickImage_ClickImage__GoBack__2V_Eq:hover {
      -webkit-transform: scale(1.2);
              transform: scale(1.2); }
    @media only screen and (max-width: 68.75em) {
      .ClickImage_ClickImage__GoBack__2V_Eq {
        z-index: 15000;
        -webkit-filter: none;
                filter: none;
        background-color: #ccc;
        padding: .5rem;
        width: 5rem;
        height: 5rem;
        top: 2rem;
        left: 2rem; } }
  .ClickImage_ClickImage__Bookmark__Nr84j {
    width: 2rem;
    height: 2rem;
    -webkit-filter: saturate(0) brightness(2.5);
            filter: saturate(0) brightness(2.5);
    cursor: pointer; }
    .ClickImage_ClickImage__Bookmark__Nr84j:hover {
      -webkit-filter: contrast(3) saturate(0.8);
              filter: contrast(3) saturate(0.8); }
    .ClickImage_ClickImage__Bookmark_fav__3caEJ {
      -webkit-filter: contrast(3) saturate(0.8);
              filter: contrast(3) saturate(0.8); }
      .ClickImage_ClickImage__Bookmark_fav__3caEJ:hover {
        -webkit-filter: saturate(0) brightness(2.5);
                filter: saturate(0) brightness(2.5); }

.VideoSummary_VideoSummary__30oyC {
  color: inherit;
  padding: 1rem;
  border-bottom: 3px solid black;
  border-left: 3px solid black;
  width: 100%; }
  @media only screen and (max-width: 68.75em) {
    .VideoSummary_VideoSummary__30oyC {
      width: 140%;
      overflow-y: auto;
      padding-right: 3rem; } }
  @media only screen and (max-width: 37.5em) {
    .VideoSummary_VideoSummary__30oyC {
      width: 100%; } }
  .VideoSummary_VideoSummary__RateFav__3Q30y {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .VideoSummary_VideoSummary__DescList__309By {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0; }
    @media only screen and (max-width: 43.75em) {
      .VideoSummary_VideoSummary__DescList__309By {
        flex-direction: column; }
        .VideoSummary_VideoSummary__DescList__309By > *:first-child {
          margin-bottom: 1rem; } }
  .VideoSummary_VideoSummary__DescItem__3g7lP {
    display: flex;
    flex-direction: column; }
  .VideoSummary_VideoSummary__DescTitle__1ltl7 {
    font-size: 1.6rem; }
    @media only screen and (max-width: 68.75em) {
      .VideoSummary_VideoSummary__DescTitle__1ltl7 {
        font-size: 1.8rem; } }
  .VideoSummary_VideoSummary__DescText__3pkrO {
    font-size: 1.4rem; }
    @media only screen and (max-width: 68.75em) {
      .VideoSummary_VideoSummary__DescText__3pkrO {
        font-size: 1.8rem; } }
  .VideoSummary_VideoSummary__Overview__kWSn8 {
    font-size: 1.4rem; }
    @media only screen and (max-width: 68.75em) {
      .VideoSummary_VideoSummary__Overview__kWSn8 {
        font-size: 1.8rem; } }
  .VideoSummary_VideoSummary__Genres__1chKN {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.4rem; }
    @media only screen and (max-width: 68.75em) {
      .VideoSummary_VideoSummary__Genres__1chKN {
        font-size: 2rem; } }
    .VideoSummary_VideoSummary__Genres__1chKN > *:not(:last-child) {
      margin-right: 1rem;
      border-right: 1px solid #797474;
      padding-right: 1rem; }

.Header_Header__QFB2P {
  position: relative;
  display: flex;
  height: 75vh;
  width: 100%;
  overflow: hidden; }
  @media only screen and (max-width: 68.75em) {
    .Header_Header__QFB2P {
      flex-direction: column;
      height: 100vh; } }
  .Header_Header__SideDrawer__2KRw6 {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 25%;
    width: 50rem;
    -webkit-transition: all 75ms ease-out;
    transition: all 75ms ease-out;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    opacity: 1; }
    @media only screen and (max-width: 68.75em) {
      .Header_Header__SideDrawer__2KRw6 {
        flex-direction: row;
        width: 100%;
        max-width: 100%;
        height: 50%;
        -webkit-transition: all 100ms ease-out;
        transition: all 100ms ease-out; } }
    @media only screen and (max-width: 43.75em) {
      .Header_Header__SideDrawer__2KRw6 {
        height: 40%; } }
    @media only screen and (max-width: 37.5em) {
      .Header_Header__SideDrawer__2KRw6 {
        flex-direction: column;
        height: 90%; } }
    .Header_Header__SideDrawer_shrink__3dMrS {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
      width: 0;
      visibility: hidden;
      opacity: 0; }
      @media only screen and (max-width: 68.75em) {
        .Header_Header__SideDrawer_shrink__3dMrS {
          -webkit-transform: translateX(0) translateY(-100%);
                  transform: translateX(0) translateY(-100%);
          width: 100%;
          height: 0; } }
  .Header_Header__QFB2P:hover .Header_Header__SideDrawerToggle__2GvJ_ {
    visibility: visible;
    opacity: 1; }
  .Header_Header__Share__3w2h3 {
    display: block;
    position: absolute;
    z-index: 200;
    right: 1rem;
    top: 5.5rem;
    width: 2.5rem;
    height: 2.5rem;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
    cursor: pointer; }
    .Header_Header__Share__3w2h3:hover {
      -webkit-filter: brightness(1.3);
              filter: brightness(1.3); }
  .Header_Header__SideDrawerToggle__2GvJ_ {
    position: absolute;
    z-index: 100;
    right: 1rem;
    top: 1rem;
    visibility: hidden;
    opacity: 0;
    background-color: #797474;
    -webkit-transition: all 300ms ease-in-out 200ms, background-color 300ms ease-in-out, -webkit-transform 400ms ease-out;
    transition: all 300ms ease-in-out 200ms, background-color 300ms ease-in-out, -webkit-transform 400ms ease-out;
    transition: all 300ms ease-in-out 200ms, background-color 300ms ease-in-out, transform 400ms ease-out;
    transition: all 300ms ease-in-out 200ms, background-color 300ms ease-in-out, transform 400ms ease-out, -webkit-transform 400ms ease-out;
    cursor: pointer; }
    @media only screen and (max-width: 68.75em) {
      .Header_Header__SideDrawerToggle__2GvJ_ {
        visibility: visible;
        opacity: 1;
        width: 3.5rem;
        height: 3.5rem;
        top: auto;
        top: initial;
        right: auto;
        right: initial;
        bottom: .5rem;
        left: 1rem; } }
    @media only screen and (max-width: 37.5em) {
      .Header_Header__SideDrawerToggle__2GvJ_ {
        width: 5rem;
        height: 5rem; } }

.Image_Image__1ImxQ, .Image_Image__NA__3Mtrd, .Image_Image__Country__1A3QW {
  display: block;
  object-fit: contain;
  width: 100%;
  box-shadow: -1rem 1.5rem 3rem rgba(0, 0, 0, 0.5); }

.Image_Image__1ImxQ, .Image_Image__NA__3Mtrd {
  object-position: 0 .1rem;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  -webkit-filter: blur(0.1px);
          filter: blur(0.1px); }

.Image_Image__NA__3Mtrd, .Image_Image__Country__1A3QW {
  object-position: 50% 50%;
  height: 100%;
  background-color: #888; }

.Image_Image__Info__1kLZw {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  visibility: hidden;
  opacity: 0;
  z-index: 1;
  width: 5rem;
  height: 5rem;
  -webkit-transition: all 250ms ease-out;
  transition: all 250ms ease-out;
  -webkit-clip-path: circle(50%);
          clip-path: circle(50%);
  background-color: #fff; }

.Image_Image__Wrapper__1yCeY, .Image_Image__WrapperCountry__10Idv {
  position: relative;
  height: 25rem;
  width: inherit;
  overflow: hidden;
  border-radius: 2rem; }

.Image_Image__Wrapper__1yCeY {
  cursor: pointer; }
  .Image_Image__Wrapper__1yCeY:hover .Image_Image__1ImxQ,
  .Image_Image__Wrapper__1yCeY:hover .Image_Image__NA__3Mtrd {
    -webkit-filter: blur(0.3rem);
            filter: blur(0.3rem);
    -webkit-transform: scale(1.05) translateY(0.8rem);
            transform: scale(1.05) translateY(0.8rem); }
    .Image_Image__Wrapper__1yCeY:hover .Image_Image__Info__1kLZw,
    .Image_Image__Wrapper__1yCeY:hover .Image_Image__NA__Info__2F7bw {
      visibility: visible;
      opacity: 1; }

.Name_Name__Staff__3mrHK {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 2rem;
  font-weight: 300;
  margin: 1rem 0;
  height: 2em; }

.Name_Name__Review__11uU4 {
  display: block;
  font-size: 2.5rem;
  margin-bottom: .75rem; }

.Description_Description__1TwCV {
  display: block;
  font-weight: 300; }
  .Description_Description__Character__3tReC {
    font-size: 2rem;
    margin: 1rem 0;
    height: 2em; }
  .Description_Description__Department__3gzr3 {
    font-size: 1.6rem;
    height: 2em; }
  .Description_Description__Job__Qa6pk {
    font-size: 2rem;
    margin: 1rem 1rem 0; }
  .Description_Description__Country__3hYyQ {
    font-size: 2rem;
    margin: 1rem 1rem 0; }

.Staff_Staff__2hPNs {
  width: 18.5rem;
  height: 100%;
  margin: 0 auto; }

.Subtitle_Subtitle__3ZH63 {
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  .Subtitle_Subtitle__MoreInfo__3FBUF {
    white-space: nowrap;
    font-size: 5rem;
    font-weight: 300; }
    @media only screen and (max-width: 25em) {
      .Subtitle_Subtitle__MoreInfo__3FBUF {
        white-space: normal;
        white-space: initial;
        text-align: center; } }
    .Subtitle_Subtitle__MoreInfo__3FBUF::before, .Subtitle_Subtitle__MoreInfo__3FBUF::after {
      display: block;
      content: '';
      border: 0;
      height: 1.15px;
      width: 100%; }
    .Subtitle_Subtitle__MoreInfo__3FBUF::before {
      margin-right: 2rem;
      background-image: -webkit-linear-gradient(right, #fff, #797474, #0f0f0f, transparent);
      background-image: linear-gradient(to left, #fff, #797474, #0f0f0f, transparent); }
    .Subtitle_Subtitle__MoreInfo__3FBUF::after {
      margin-left: 2rem;
      background-image: -webkit-linear-gradient(left, #fff, #797474, #0f0f0f, transparent);
      background-image: linear-gradient(to right, #fff, #797474, #0f0f0f, transparent); }
  .Subtitle_Subtitle__Profile__3xASo {
    display: block;
    font-size: 2rem;
    font-weight: 300; }
  .Subtitle_Subtitle__Work__31i6u {
    font-size: 2.25rem;
    font-weight: 500;
    margin-bottom: 1rem; }

.ReviewContent_ReviewContent__2Xl5m {
  font-size: 1.8rem;
  line-height: 1.6; }
  .ReviewContent_ReviewContent__Minified__3n0na {
    cursor: pointer; }

.Reviews_Reviews__2DwU9 {
  width: 100%;
  margin: 3rem auto 5rem;
  box-shadow: 0 2rem 7rem rgba(0, 0, 0, 0.2);
  padding: 2rem 3rem;
  border-radius: 2rem; }

.Money_Money__11MSr {
  font-size: 2rem; }

.Money_Money__nVLm5 {
  width: 100%;
  height: 100%;
  text-align: center;
  white-space: nowrap;
  text-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.4); }
  @media only screen and (max-width: 43.75em) {
    .Money_Money__nVLm5 {
      white-space: normal;
      white-space: initial;
      margin: 0 3rem; } }
  .Money_Money__Title__3QwNH {
    font-size: 3rem;
    font-weight: 300; }
  .Money_Money__Img__38MTw {
    display: block;
    height: 13rem;
    width: 13rem;
    margin: 1.5rem auto;
    -webkit-filter: drop-shadow(0 0.5rem 3rem rgba(0, 0, 0, 0.5));
            filter: drop-shadow(0 0.5rem 3rem rgba(0, 0, 0, 0.5)); }

.slick-arrow {
  display: block;
  z-index: 5; }

.slick-prev {
  left: -7.5rem !important; }

.slick-next {
  right: -7.5rem !important; }

.slick-dots {
  bottom: -4rem !important; }
  .slick-dots:not(.slick-active) button::before {
    color: white !important; }

.slick-active button::before {
  color: white !important; }

.slick-slide {
  text-align: center !important; }

.Body_Body__2cFcO {
  max-width: 80%;
  margin: 0 auto; }
  .Body_Body__NotAvailable__14p5j {
    display: block;
    font-size: 3rem;
    text-align: center;
    text-transform: uppercase;
    padding: 10rem 0; }
  .Body_Body__Staff__2wp54 {
    width: 85%;
    margin: 7rem auto; }
  .Body_Body__Reviews__3h0dS {
    display: block;
    width: 100%;
    margin: 7rem auto; }
  .Body_Body__Money__2MTfr {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    max-width: 120rem;
    margin: 7rem auto;
    padding: 5rem 10rem; }

.MoreInfo_MoreInfo__rtBBS {
  width: 100%;
  height: 100%; }

.Category_Category__3a_f_ {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
  font-weight: 300;
  text-transform: uppercase;
  width: 100%;
  padding: 1rem 0;
  margin-bottom: 2rem; }
  .Category_Category__3a_f_::before, .Category_Category__3a_f_::after {
    display: block;
    content: '';
    border: 0;
    height: 1.15px;
    width: 100%; }
  .Category_Category__3a_f_::before {
    margin-right: 3rem;
    background-image: -webkit-linear-gradient(right, #fff, #797474, #0f0f0f, transparent);
    background-image: linear-gradient(to left, #fff, #797474, #0f0f0f, transparent); }
  .Category_Category__3a_f_::after {
    margin-left: 3rem;
    background-image: -webkit-linear-gradient(left, #fff, #797474, #0f0f0f, transparent);
    background-image: linear-gradient(to right, #fff, #797474, #0f0f0f, transparent); }
  .Category_Category__Text__jVCAO {
    display: block;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    margin-left: 1.5rem;
    letter-spacing: 1.5rem; }
    @media only screen and (max-width: 34.375em) {
      .Category_Category__Text__jVCAO {
        white-space: normal;
        white-space: initial; } }

.Header_Header__3CTJ5 {
  display: inline-block;
  font-size: 3rem;
  font-weight: 300; }
  @media only screen and (max-width: 37.5em) {
    .Header_Header__3CTJ5 {
      font-size: 2.3rem; } }

.Title_Title__Carousel__2CYhf {
  font-size: 5rem;
  font-weight: 300; }
  @media only screen and (max-width: 53.125em) {
    .Title_Title__Carousel__2CYhf {
      font-size: 4rem; } }
  @media only screen and (max-width: 37.5em) {
    .Title_Title__Carousel__2CYhf {
      font-size: 4.3rem; } }

.Title_Title__Thumbnail__2o4kq {
  display: inline;
  font-weight: 700;
  font-size: 1.7rem;
  text-align: center; }

.Title_Title__Search__4etJX {
  display: block;
  font-size: 5rem;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  text-align: center;
  letter-spacing: 1rem;
  text-transform: uppercase; }

.Title_Title__Profile__2KLsJ {
  display: block;
  font-size: 3rem;
  font-weight: 300; }

.Title_Title__Work__2fJTn {
  font-size: 3rem;
  font-weight: 500; }

.Rating_Rating__Carousel__1MtZ4 {
  font-size: 2.25rem;
  font-weight: 300; }

.Rating_Rating__Thumbnail__1jgIT {
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff; }

.Info_Info__1UeFS, .Info_Info__1UeFS:link, .Info_Info__1UeFS:visited {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  text-decoration: none;
  height: 100%;
  width: 100%; }

.Info_Info__1UeFS:hover .Info_Info__Img__1n8kV {
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  -webkit-filter: contrast(1.05) brightness(0.8);
          filter: contrast(1.05) brightness(0.8); }

.Info_Info__Text__2Bgqf {
  position: absolute;
  bottom: 10rem;
  left: 10rem;
  color: #fff;
  background-image: -webkit-linear-gradient(right bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.55) 50%, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0) 95%), -webkit-linear-gradient(bottom left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.55) 50%, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0) 95%);
  background-image: linear-gradient(to left top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.55) 50%, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0) 95%), linear-gradient(to top right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.55) 50%, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0) 95%);
  padding: 2rem 4rem;
  max-width: 60%;
  white-space: pre-wrap; }
  @media only screen and (max-width: 56.25em) {
    .Info_Info__Text__2Bgqf {
      bottom: 5rem; } }
  @media only screen and (max-width: 43.75em) {
    .Info_Info__Text__2Bgqf {
      right: 10rem;
      max-width: 100%; } }
  @media only screen and (max-width: 37.5em) {
    .Info_Info__Text__2Bgqf {
      left: 0;
      right: 0;
      bottom: 0;
      padding: 3rem 4rem;
      text-align: center; } }
  .Info_Info__Text__2Bgqf > *:not(:last-child) {
    margin-bottom: 1rem; }

.Info_Info__Rating__rfoUC {
  display: flex;
  align-items: flex-end; }
  @media only screen and (max-width: 37.5em) {
    .Info_Info__Rating__rfoUC > *:first-child {
      margin-left: auto; }
    .Info_Info__Rating__rfoUC > *:last-child {
      margin-right: auto; } }
  .Info_Info__Rating__rfoUC > *:first-child {
    padding-right: 1rem;
    margin-right: 1rem;
    border-right: 2px solid #fff; }
  .Info_Info__Rating__rfoUC > *:last-child {
    margin-left: 1.5rem; }

.Dot_Dot__D4Ydb {
  width: 2rem;
  height: 2rem;
  -webkit-shape-outside: circle(50%);
          shape-outside: circle(50%);
  border: 2px solid #000;
  border-radius: 50%;
  background-color: #111;
  cursor: pointer; }
  .Dot_Dot_active__1LVhQ {
    cursor: default;
    background-color: #ccc; }

.Carousel_Carousel__2ifo4 {
  position: relative;
  display: grid;
  justify-items: center;
  align-content: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin-bottom: 5rem; }
  .Carousel_Carousel__Info__3Xb1f {
    white-space: nowrap;
    width: 102%;
    height: calc(100vh - 8rem);
    -webkit-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out; }
    @media only screen and (max-width: 75em) {
      .Carousel_Carousel__Info__3Xb1f {
        height: 85vh; } }
    @media only screen and (max-width: 68.75em) {
      .Carousel_Carousel__Info__3Xb1f {
        height: 80vh; } }
    @media only screen and (max-width: 59.375em) {
      .Carousel_Carousel__Info__3Xb1f {
        height: 70vh; } }
    @media only screen and (max-width: 53.125em) {
      .Carousel_Carousel__Info__3Xb1f {
        height: 55vh; } }
    @media only screen and (max-width: 40.625em) {
      .Carousel_Carousel__Info__3Xb1f {
        height: 40vh; } }
  .Carousel_Carousel__Dots__1-SZk {
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: flex;
    margin-bottom: 2rem;
    padding: 1rem 2rem;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 100px;
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out; }
    .Carousel_Carousel__Dots__1-SZk > *:not(:last-child) {
      margin-right: 0.75rem; }
    .Carousel_Carousel__Dots__1-SZk:hover {
      background-color: rgba(255, 255, 255, 0.3); }
    @media only screen and (max-width: 56.25em) {
      .Carousel_Carousel__Dots__1-SZk {
        display: none; } }
  .Carousel_Carousel__Arrows__3ud4B {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 10;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 200ms ease-out;
    transition: all 200ms ease-out; }
    @media only screen and (max-width: 62.5em) {
      .Carousel_Carousel__Arrows__3ud4B {
        opacity: 1;
        visibility: visible; } }
    .Carousel_Carousel__Arrows_left__3t1__ {
      left: 2%; }
      .Carousel_Carousel__Arrows_left__3t1__:hover {
        -webkit-transform: translate(-0.25rem, -50%);
                transform: translate(-0.25rem, -50%);
        -webkit-filter: drop-shadow(0.75rem 0.75rem 0.7rem rgba(0, 0, 0, 0.5)) brightness(1.5);
                filter: drop-shadow(0.75rem 0.75rem 0.7rem rgba(0, 0, 0, 0.5)) brightness(1.5); }
    .Carousel_Carousel__Arrows_right__2OjAE {
      right: 2%; }
      .Carousel_Carousel__Arrows_right__2OjAE:hover {
        -webkit-transform: translate(0.25rem, -50%);
                transform: translate(0.25rem, -50%);
        -webkit-filter: drop-shadow(-0.75rem 0.75rem 0.7rem rgba(0, 0, 0, 0.5)) brightness(1.5);
                filter: drop-shadow(-0.75rem 0.75rem 0.7rem rgba(0, 0, 0, 0.5)) brightness(1.5); }
  .Carousel_Carousel__2ifo4:hover .Carousel_Carousel__Arrows__3ud4B {
    visibility: visible;
    opacity: 1; }

.Thumbnail_Thumbnail__T__rV {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  width: 100%; }
  .Thumbnail_Thumbnail__Item__KrNj0 {
    display: inline-block;
    min-width: 14rem;
    width: auto;
    height: 24rem;
    position: relative;
    -webkit-transition: all 200ms ease-out;
    transition: all 200ms ease-out;
    border-radius: .5rem;
    margin-bottom: 1rem;
    box-shadow: -1.5rem 1rem 2.5rem rgba(51, 51, 51, 0.5);
    cursor: pointer;
    overflow: hidden; }
    @media only screen and (max-width: 50em) {
      .Thumbnail_Thumbnail__Item__KrNj0 {
        min-width: 19rem;
        height: auto; } }
    @media only screen and (max-width: 37.5em) {
      .Thumbnail_Thumbnail__Item__KrNj0 {
        min-width: 0;
        min-width: initial; } }
    .Thumbnail_Thumbnail__Item__KrNj0::before {
      content: '';
      display: block;
      z-index: 5;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      -webkit-transition: all 300ms ease-out;
      transition: all 300ms ease-out;
      background-image: -webkit-linear-gradient(top left, rgba(0, 0, 0, 0.1) 15%, rgba(0, 0, 0, 0.8) 80%);
      background-image: linear-gradient(to bottom right, rgba(0, 0, 0, 0.1) 15%, rgba(0, 0, 0, 0.8) 80%); }
    .Thumbnail_Thumbnail__Item__KrNj0:hover {
      box-shadow: -1rem 1rem 2rem rgba(0, 0, 0, 0.5), 0.25rem 0.25rem 0 #0051ff, 0.25rem -0.25rem 0 #0051ff, -0.25rem 0.25rem 0 #0051ff, -0.25rem -0.25rem 0 #0051ff; }
      .Thumbnail_Thumbnail__Item__KrNj0:hover::before {
        opacity: 1; }
    .Thumbnail_Thumbnail__Item__KrNj0 > *:first-child {
      -webkit-transition: all 200ms ease-out;
      transition: all 200ms ease-out; }
    .Thumbnail_Thumbnail__Item__KrNj0:hover > *:first-child {
      -webkit-transform: scale(1.03);
              transform: scale(1.03); }
  .Thumbnail_Thumbnail__Item__KrNj0:hover .Thumbnail_Thumbnail__Rating__18LfP {
    opacity: 1; }
  .Thumbnail_Thumbnail__Img__17fOc {
    border-radius: .5rem; }
  .Thumbnail_Thumbnail__Rating__18LfP {
    display: flex;
    align-items: center;
    z-index: 10;
    width: 100%;
    position: absolute;
    bottom: 5%;
    left: 5%;
    opacity: 0;
    -webkit-transition: opacity 200ms ease-out;
    transition: opacity 200ms ease-out; }
    .Thumbnail_Thumbnail__Rating__18LfP > *:first-child {
      margin-right: 1rem; }

.FilmList_FilmList__RaTSN {
  max-width: 120rem;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 1rem 0; }
  .FilmList_FilmList__List__2yixz {
    display: grid;
    width: 100%;
    grid-gap: 4rem;
    justify-content: center;
    align-content: center;
    align-items: start;
    grid-template-columns: repeat(auto-fit, minmax(-webkit-min-content, 16rem));
    grid-template-columns: repeat(auto-fit, minmax(min-content, 16rem));
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content; }
    @media only screen and (max-width: 50em) {
      .FilmList_FilmList__List__2yixz {
        width: 100%;
        margin: 0 auto; } }
    @media only screen and (max-width: 37.5em) {
      .FilmList_FilmList__List__2yixz {
        grid-gap: .5rem; } }
    @media only screen and (max-width: 18.75em) {
      .FilmList_FilmList__List__2yixz {
        grid-gap: 0; } }

.SpinnerSecondary_SpinnerSecondaryWrapper__3onFz {
  height: calc(50vh);
  position: relative; }

.SpinnerSecondary_SpinnerSecondary__12IL5 {
  margin: 100px auto;
  width: 8rem;
  height: 8rem;
  position: absolute;
  top: 30%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
  .SpinnerSecondary_SpinnerSecondary__Child__2HoOd {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; }
    .SpinnerSecondary_SpinnerSecondary__Child__2HoOd::before {
      content: '';
      display: block;
      margin: 0 auto;
      width: 15%;
      height: 15%;
      background-color: #ccc;
      border-radius: 100%;
      -webkit-animation: SpinnerSecondary_SpinnerSecondaryBounceDelay__3LBto 1.2s infinite ease-in-out both;
              animation: SpinnerSecondary_SpinnerSecondaryBounceDelay__3LBto 1.2s infinite ease-in-out both; }
  .SpinnerSecondary_SpinnerSecondary_2__2eFj- {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg); }
    .SpinnerSecondary_SpinnerSecondary_2__2eFj-::before {
      -webkit-animation-delay: -1.1s;
              animation-delay: -1.1s; }
  .SpinnerSecondary_SpinnerSecondary_3__3E2uZ {
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg); }
    .SpinnerSecondary_SpinnerSecondary_3__3E2uZ::before {
      -webkit-animation-delay: -1s;
              animation-delay: -1s; }
  .SpinnerSecondary_SpinnerSecondary_4__1lolJ {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
    .SpinnerSecondary_SpinnerSecondary_4__1lolJ::before {
      -webkit-animation-delay: -0.9s;
              animation-delay: -0.9s; }
  .SpinnerSecondary_SpinnerSecondary_5__3NRgZ {
    -webkit-transform: rotate(120deg);
            transform: rotate(120deg); }
    .SpinnerSecondary_SpinnerSecondary_5__3NRgZ::before {
      -webkit-animation-delay: -0.8s;
              animation-delay: -0.8s; }
  .SpinnerSecondary_SpinnerSecondary_6__Ff8an {
    -webkit-transform: rotate(150deg);
            transform: rotate(150deg); }
    .SpinnerSecondary_SpinnerSecondary_6__Ff8an::before {
      -webkit-animation-delay: -0.7s;
              animation-delay: -0.7s; }
  .SpinnerSecondary_SpinnerSecondary_7__2EHSE {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
    .SpinnerSecondary_SpinnerSecondary_7__2EHSE::before {
      -webkit-animation-delay: -0.6s;
              animation-delay: -0.6s; }
  .SpinnerSecondary_SpinnerSecondary_8__3FLZN {
    -webkit-transform: rotate(210deg);
            transform: rotate(210deg); }
    .SpinnerSecondary_SpinnerSecondary_8__3FLZN::before {
      -webkit-animation-delay: -0.5s;
              animation-delay: -0.5s; }
  .SpinnerSecondary_SpinnerSecondary_9__YZk2W {
    -webkit-transform: rotate(240deg);
            transform: rotate(240deg); }
    .SpinnerSecondary_SpinnerSecondary_9__YZk2W::before {
      -webkit-animation-delay: -0.4s;
              animation-delay: -0.4s; }
  .SpinnerSecondary_SpinnerSecondary_10__3vZEc {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); }
    .SpinnerSecondary_SpinnerSecondary_10__3vZEc::before {
      -webkit-animation-delay: -0.3s;
              animation-delay: -0.3s; }
  .SpinnerSecondary_SpinnerSecondary_11__25Qbj {
    -webkit-transform: rotate(300deg);
            transform: rotate(300deg); }
    .SpinnerSecondary_SpinnerSecondary_11__25Qbj::before {
      -webkit-animation-delay: -0.2s;
              animation-delay: -0.2s; }
  .SpinnerSecondary_SpinnerSecondary_12__19x03 {
    -webkit-transform: rotate(330deg);
            transform: rotate(330deg); }
    .SpinnerSecondary_SpinnerSecondary_12__19x03::before {
      -webkit-animation-delay: -0.1s;
              animation-delay: -0.1s; }

@-webkit-keyframes SpinnerSecondary_SpinnerSecondaryBounceDelay__3LBto {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes SpinnerSecondary_SpinnerSecondaryBounceDelay__3LBto {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

.PageCount_PageCount__Vc1rg {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  padding: .5rem 2rem;
  -webkit-filter: drop-shadow(-0.6rem 0.8rem 0.3rem black);
          filter: drop-shadow(-0.6rem 0.8rem 0.3rem black); }
  .PageCount_PageCount__Vc1rg::before, .PageCount_PageCount__Vc1rg::after {
    display: block;
    content: '';
    border: 0;
    height: 1.15px;
    width: 100%; }
  .PageCount_PageCount__Vc1rg::before {
    margin-right: 3rem;
    background-image: -webkit-linear-gradient(right, #fff, #797474, #0f0f0f, transparent);
    background-image: linear-gradient(to left, #fff, #797474, #0f0f0f, transparent); }
  .PageCount_PageCount__Vc1rg::after {
    margin-left: 3rem;
    background-image: -webkit-linear-gradient(left, #fff, #797474, #0f0f0f, transparent);
    background-image: linear-gradient(to right, #fff, #797474, #0f0f0f, transparent); }
  .PageCount_PageCount_Discover__3l3Jv {
    color: #ccc;
    margin: 3rem auto;
    width: 100%; }
  .PageCount_PageCount_Main__2-9ME {
    color: #ccc;
    margin: 3rem auto;
    width: 100%; }
  .PageCount_PageCount__Mid__857VM {
    display: inline-block;
    font-size: 2.9rem;
    margin: 0 2rem; }
  .PageCount_PageCount__Side__1NeUv {
    display: inline-block;
    font-size: 2rem; }

.DiscoverBody_DiscoverBody__1-lq8 {
  position: relative;
  padding-bottom: 5rem;
  margin-bottom: 15rem; }
  .DiscoverBody_DiscoverBody__List__3Wskg {
    height: 100%; }
    @media only screen and (max-width: 56.25em) {
      .DiscoverBody_DiscoverBody__List__3Wskg {
        width: 90%;
        margin: 0 auto; } }
    .DiscoverBody_DiscoverBody__List_hide__7t0Te {
      display: none; }
  .DiscoverBody_DiscoverBody__Arrows__x_6Bs {
    position: absolute;
    top: 42.5%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 10;
    -webkit-transition: all 200ms ease-out;
    transition: all 200ms ease-out; }
    .DiscoverBody_DiscoverBody__Arrows_left__8H76h {
      left: -4%; }
      @media only screen and (max-width: 75em) {
        .DiscoverBody_DiscoverBody__Arrows_left__8H76h {
          left: -3%; } }
      @media only screen and (max-width: 50em) {
        .DiscoverBody_DiscoverBody__Arrows_left__8H76h {
          top: auto;
          top: initial;
          bottom: -5rem;
          left: 40%; } }
      @media only screen and (max-width: 37.5em) {
        .DiscoverBody_DiscoverBody__Arrows_left__8H76h {
          left: 30%; } }
      .DiscoverBody_DiscoverBody__Arrows_left__8H76h:hover {
        -webkit-transform: translate(-0.25rem, -50%);
                transform: translate(-0.25rem, -50%);
        -webkit-filter: drop-shadow(0.75rem 0.75rem 0.7rem rgba(0, 0, 0, 0.5)) brightness(1.5);
                filter: drop-shadow(0.75rem 0.75rem 0.7rem rgba(0, 0, 0, 0.5)) brightness(1.5); }
    .DiscoverBody_DiscoverBody__Arrows_right__3xBmE {
      right: -5%; }
      @media only screen and (max-width: 75em) {
        .DiscoverBody_DiscoverBody__Arrows_right__3xBmE {
          right: -3%; } }
      @media only screen and (max-width: 50em) {
        .DiscoverBody_DiscoverBody__Arrows_right__3xBmE {
          top: auto;
          top: initial;
          bottom: -5rem;
          right: 40%; } }
      @media only screen and (max-width: 37.5em) {
        .DiscoverBody_DiscoverBody__Arrows_right__3xBmE {
          right: 30%; } }
      .DiscoverBody_DiscoverBody__Arrows_right__3xBmE:hover {
        -webkit-transform: translate(0.25rem, -50%);
                transform: translate(0.25rem, -50%);
        -webkit-filter: drop-shadow(-0.75rem 0.75rem 0.7rem rgba(0, 0, 0, 0.5)) brightness(1.5);
                filter: drop-shadow(-0.75rem 0.75rem 0.7rem rgba(0, 0, 0, 0.5)) brightness(1.5); }

.Spinner_SpinnerWrapper__2QihU {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000;
  -webkit-transition: all 1500ms ease-in-out 500ms;
  transition: all 1500ms ease-in-out 500ms;
  white-space: nowrap; }
  @media only screen and (max-width: 37.5em) {
    .Spinner_SpinnerWrapper__2QihU {
      white-space: normal;
      white-space: initial;
      text-align: center; } }
  .Spinner_SpinnerWrapper_enter__24UHe {
    background-color: #0f0f0f;
    background-image: -webkit-radial-gradient(30% 30%, circle, #131313 0%, #030303 65%);
    background-image: radial-gradient(circle at 30% 30%, #131313 0%, #030303 65%); }
  .Spinner_SpinnerWrapper_exit_active__ZC6Og {
    background-color: #25064C;
    background-image: -webkit-radial-gradient(30% 30%, circle, #131313 0%, #030303 65%);
    background-image: radial-gradient(circle at 30% 30%, #131313 0%, #030303 65%);
    opacity: 0; }
  .Spinner_SpinnerWrapper_exit_done__NjjAh {
    background-image: -webkit-radial-gradient(30% 30%, circle, #131313 0%, #030303 65%);
    background-image: radial-gradient(circle at 30% 30%, #131313 0%, #030303 65%);
    opacity: 0; }

.Spinner_SpinnerText__Title__Eh5Ch {
  font-weight: 300;
  font-size: 6.5rem;
  letter-spacing: 1.5rem;
  text-transform: uppercase;
  display: inline-block;
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.Spinner_SpinnerText__Subtitle__2jucX {
  font-weight: 300;
  font-size: 5rem;
  letter-spacing: 1rem;
  text-transform: uppercase;
  display: inline-block;
  position: absolute;
  bottom: 32%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.Spinner_Spinner__VdE4v {
  margin: 2rem auto;
  width: 10rem;
  height: 10rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: rotateZ(45deg) translate(-70.71%, 0);
          transform: rotateZ(45deg) translate(-70.71%, 0);
  -webkit-transform-origin: center center;
          transform-origin: center center; }
  .Spinner_Spinner__Cube__3Kjdd {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
    .Spinner_Spinner__Cube__3Kjdd::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #490049;
      -webkit-animation: Spinner_sk-foldCubeAngle__3UpFa 2.4s infinite linear both;
              animation: Spinner_sk-foldCubeAngle__3UpFa 2.4s infinite linear both;
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%; }
    .Spinner_Spinner__Cube_2__2DuBb {
      -webkit-transform: scale(1.1) rotateZ(90deg);
              transform: scale(1.1) rotateZ(90deg); }
      .Spinner_Spinner__Cube_2__2DuBb::before {
        -webkit-animation-delay: 0.3s;
                animation-delay: 0.3s; }
    .Spinner_Spinner__Cube_3__222x2 {
      -webkit-transform: scale(1.1) rotateZ(180deg);
              transform: scale(1.1) rotateZ(180deg); }
      .Spinner_Spinner__Cube_3__222x2::before {
        -webkit-animation-delay: 0.6s;
                animation-delay: 0.6s; }
    .Spinner_Spinner__Cube_4__10mV- {
      -webkit-transform: scale(1.1) rotateZ(270deg);
              transform: scale(1.1) rotateZ(270deg); }
      .Spinner_Spinner__Cube_4__10mV-::before {
        -webkit-animation-delay: 0.9s;
                animation-delay: 0.9s; }

@-webkit-keyframes Spinner_sk-foldCubeAngle__3UpFa {
  0%, 10% {
    -webkit-transform: perspective(14rem) rotateX(-180deg);
            transform: perspective(14rem) rotateX(-180deg);
    opacity: 0; }
  25%, 75% {
    -webkit-transform: perspective(14rem) rotateX(0deg);
            transform: perspective(14rem) rotateX(0deg);
    opacity: 1; }
  90%, 100% {
    -webkit-transform: perspective(14rem) rotateY(180deg);
            transform: perspective(14rem) rotateY(180deg);
    opacity: 0; } }

@keyframes Spinner_sk-foldCubeAngle__3UpFa {
  0%, 10% {
    -webkit-transform: perspective(14rem) rotateX(-180deg);
            transform: perspective(14rem) rotateX(-180deg);
    opacity: 0; }
  25%, 75% {
    -webkit-transform: perspective(14rem) rotateX(0deg);
            transform: perspective(14rem) rotateX(0deg);
    opacity: 1; }
  90%, 100% {
    -webkit-transform: perspective(14rem) rotateY(180deg);
            transform: perspective(14rem) rotateY(180deg);
    opacity: 0; } }

.Movies_Movies__Body__2R44t {
  padding: 5rem 0;
  margin: 0 auto;
  width: 120rem;
  max-width: 90%; }
  @media only screen and (max-width: 37.5em) {
    .Movies_Movies__Body__2R44t {
      width: 100%;
      max-width: 100%; } }

.Input_Input__2baUC {
  outline: none;
  font: inherit;
  display: inline-block;
  border: none;
  height: 100%;
  width: 100%; }
  .Input_Input__2baUC:focus {
    outline: none; }
  .Input_Input__DiscoverText__KnvnZ {
    display: block;
    grid-column: -1 / 1;
    width: 100%;
    padding: 0.75rem 2rem;
    font-family: inherit;
    font-size: 1.6rem;
    background-color: #0f0f0f;
    border-radius: 2rem;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.5);
    -webkit-transition: all 200ms ease-out;
    transition: all 200ms ease-out;
    color: #ccc; }
    .Input_Input__DiscoverText__KnvnZ:focus {
      border-radius: 0;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      background-color: #797474;
      color: #0f0f0f; }
      .Input_Input__DiscoverText__KnvnZ:focus::-webkit-input-placeholder {
        color: #0f0f0f; }
      .Input_Input__DiscoverText__KnvnZ:focus::-ms-input-placeholder {
        color: #0f0f0f; }
      .Input_Input__DiscoverText__KnvnZ:focus::placeholder {
        color: #0f0f0f; }
    .Input_Input__DiscoverText__KnvnZ::-webkit-input-placeholder {
      color: #ccc; }
    .Input_Input__DiscoverText__KnvnZ::-ms-input-placeholder {
      color: #ccc; }
    .Input_Input__DiscoverText__KnvnZ::placeholder {
      color: #ccc; }
  .Input_Input__DiscoverSubmit__39oEq {
    display: inline-block;
    font-size: 1.8rem;
    font-weight: 600;
    width: 15rem;
    height: 4rem;
    margin-top: 4rem;
    padding: 1rem 2rem;
    border: 1px solid black;
    border-radius: 2rem;
    justify-self: center;
    grid-column: 1 / -1;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.5);
    background-color: #0f0f0f;
    color: inherit;
    cursor: pointer;
    -webkit-transition: all 200ms ease-out;
    transition: all 200ms ease-out; }
    .Input_Input__DiscoverSubmit__39oEq:hover {
      -webkit-transform: translateY(-0.15rem);
              transform: translateY(-0.15rem);
      box-shadow: 0 2rem 2rem rgba(0, 0, 0, 0.5); }
    .Input_Input__DiscoverSubmit__39oEq:active {
      -webkit-transform: translateY(0.05rem);
              transform: translateY(0.05rem);
      box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.7); }
  .Input_Input__SearchbarText__3yjhb {
    width: 70%;
    height: 120%;
    border-radius: 10rem;
    font-size: 1.6rem;
    padding: 0 2rem;
    -webkit-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
    background-color: #ccc;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
    .Input_Input__SearchbarText__3yjhb::-webkit-input-placeholder {
      color: rgba(15, 15, 15, 0.7); }
    .Input_Input__SearchbarText__3yjhb::-ms-input-placeholder {
      color: rgba(15, 15, 15, 0.7); }
    .Input_Input__SearchbarText__3yjhb::placeholder {
      color: rgba(15, 15, 15, 0.7); }
    .Input_Input__SearchbarText__3yjhb:focus {
      width: 90%;
      box-shadow: 0 0.1rem 0.15rem #490049, 0 -0.1rem 0.15rem #490049, 0.1rem 0.1rem 0.15rem #490049, 0.1rem -0.1rem 0.15rem #490049, -0.1rem 0.1rem 0.15rem #490049, -0.1rem -0.1rem 0.15rem #490049; }
      @media only screen and (max-width: 75em) {
        .Input_Input__SearchbarText__3yjhb:focus {
          width: 100%; } }
      @media only screen and (max-width: 68.75em) {
        .Input_Input__SearchbarText__3yjhb:focus {
          width: 100%; } }
    @media only screen and (max-width: 75em) {
      .Input_Input__SearchbarText__3yjhb {
        width: 95%; } }
    @media only screen and (max-width: 68.75em) {
      .Input_Input__SearchbarText__3yjhb {
        width: 90%; } }
  .Input_Input__SearchbarSubmit__31rec {
    width: 7rem;
    height: 120%;
    margin-left: -6.75rem;
    padding: .6rem 0;
    border-radius: 10rem;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    background-color: #490049;
    -webkit-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
    cursor: pointer; }
    .Input_Input__SearchbarSubmit__31rec:hover {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      -webkit-animation: Input_pulse__22UcB 2s infinite;
              animation: Input_pulse__22UcB 2s infinite; }

@-webkit-keyframes Input_pulse__22UcB {
  0% {
    box-shadow: 0 0 0 0 rgba(204, 204, 204, 0.45); }
  70% {
    box-shadow: 0 0 0 10px rgba(204, 204, 204, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(204, 204, 204, 0); } }

@keyframes Input_pulse__22UcB {
  0% {
    box-shadow: 0 0 0 0 rgba(204, 204, 204, 0.45); }
  70% {
    box-shadow: 0 0 0 10px rgba(204, 204, 204, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(204, 204, 204, 0); } }

.Searchbar_Searchbar__l4kxD {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem; }

.NavItem_NavItem__13PAm {
  position: relative;
  display: inline-block;
  font-size: 2rem;
  z-index: 1;
  text-align: center;
  margin: 0 2rem;
  -webkit-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }
  .NavItem_NavItem__13PAm:hover {
    -webkit-filter: brightness(1.5) drop-shadow(0 0.25rem 1.2rem #ff0099);
            filter: brightness(1.5) drop-shadow(0 0.25rem 1.2rem #ff0099); }
  @media only screen and (max-width: 50em) {
    .NavItem_NavItem__13PAm {
      margin: 2rem 0;
      font-weight: 500;
      font-size: 6.5rem;
      text-transform: uppercase;
      letter-spacing: .5rem; } }
  .NavItem_NavItem__Img__2ZctI {
    display: block;
    width: 3.5rem;
    height: 3.5rem; }
    @media only screen and (max-width: 50em) {
      .NavItem_NavItem__Img__2ZctI {
        height: 10rem;
        width: 10rem; } }
  .NavItem_NavItem__Link__2KX8l, .NavItem_NavItem__Link__2KX8l:link, .NavItem_NavItem__Link__2KX8l:visited {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: inherit;
    width: 100%;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
  .NavItem_NavItem_active__1P9Z2 {
    position: relative;
    -webkit-filter: drop-shadow(0 0.25rem 1.2rem #ff0099);
            filter: drop-shadow(0 0.25rem 1.2rem #ff0099); }

.NavItem_NavItemAuth__cX7Fp {
  height: 8rem;
  position: relative;
  display: block;
  font-size: 2.25rem;
  z-index: 1;
  text-align: center;
  margin: 0 2rem;
  -webkit-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media only screen and (max-width: 50em) {
    .NavItem_NavItemAuth__cX7Fp {
      margin: 2rem 0;
      font-weight: 500;
      font-size: 6.5rem;
      text-transform: uppercase;
      letter-spacing: .5rem; } }
  .NavItem_NavItemAuth__cX7Fp:hover {
    -webkit-filter: brightness(1.1) drop-shadow(0 0.1rem 1.2rem rgba(255, 0, 153, 0.5)) drop-shadow(0 0.1rem 1.2rem rgba(255, 0, 153, 0.5));
            filter: brightness(1.1) drop-shadow(0 0.1rem 1.2rem rgba(255, 0, 153, 0.5)) drop-shadow(0 0.1rem 1.2rem rgba(255, 0, 153, 0.5)); }
  .NavItem_NavItemAuth__Text__P41pY {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-bottom: .3rem; }
    @media only screen and (max-width: 50em) {
      .NavItem_NavItemAuth__Text__P41pY {
        font-weight: 500; } }
  .NavItem_NavItemAuth__AuthLink__3fXmB {
    -webkit-transition: all 200ms ease-out;
    transition: all 200ms ease-out;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
    .NavItem_NavItemAuth__AuthLink__3fXmB, .NavItem_NavItemAuth__AuthLink__3fXmB:link, .NavItem_NavItemAuth__AuthLink__3fXmB:visited {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      background-color: #ccc;
      color: black;
      width: 3.6rem;
      height: 3.6rem;
      font-weight: 700;
      border-radius: 50%;
      -webkit-transition: all 200ms ease-out;
      transition: all 200ms ease-out; }
      @media only screen and (max-width: 50em) {
        .NavItem_NavItemAuth__AuthLink__3fXmB, .NavItem_NavItemAuth__AuthLink__3fXmB:link, .NavItem_NavItemAuth__AuthLink__3fXmB:visited {
          color: inherit;
          background-color: transparent; } }
  .NavItem_NavItemAuth__Dropdown__hYWN_ {
    position: absolute;
    top: 100%;
    right: 0;
    opacity: 0;
    height: 0.1;
    visibility: hidden;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transition: all 200ms ease-out;
    transition: all 200ms ease-out;
    -webkit-transform-origin: top;
            transform-origin: top;
    background-color: #797474;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem; }
    @media only screen and (max-width: 50em) {
      .NavItem_NavItemAuth__Dropdown__hYWN_ {
        display: none; } }
    .NavItem_NavItemAuth__Dropdown__hYWN_ > *:last-child {
      border-bottom-right-radius: 1rem;
      border-bottom-left-radius: 1rem; }
    .NavItem_NavItemAuth__Dropdown__hYWN_::before {
      position: absolute;
      right: 0;
      bottom: 100%;
      opacity: 0;
      height: 1rem;
      width: 3.5rem;
      content: '';
      background-color: #797474;
      -webkit-clip-path: polygon(25% 100%, 50% 0, 75% 100%);
              clip-path: polygon(25% 100%, 50% 0, 75% 100%);
      -webkit-transition: all 200ms ease-out;
      transition: all 200ms ease-out; }
  .NavItem_NavItemAuth__cX7Fp:hover .NavItem_NavItemAuth__Dropdown__hYWN_ {
    height: auto;
    opacity: 1;
    height: 1;
    visibility: visible;
    -webkit-transform: scaleY(1);
            transform: scaleY(1); }
    .NavItem_NavItemAuth__cX7Fp:hover .NavItem_NavItemAuth__Dropdown__hYWN_::before {
      opacity: 1; }
  .NavItem_NavItemAuth__DropItem__C1eIy {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 3rem;
    background-color: inherit;
    -webkit-transition: all 300ms ease-out;
    transition: all 300ms ease-out; }
    .NavItem_NavItemAuth__DropItem__C1eIy:hover {
      background-color: #490049;
      color: #ccc; }
    .NavItem_NavItemAuth__DropItem__C1eIy, .NavItem_NavItemAuth__DropItem__C1eIy:link, .NavItem_NavItemAuth__DropItem__C1eIy:visited {
      color: black;
      cursor: pointer; }
    .NavItem_NavItemAuth__DropItem_link__8msQU {
      text-decoration: none;
      color: inherit;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%; }
      .NavItem_NavItemAuth__DropItem_link__8msQU img {
        height: 3rem;
        width: 3rem; }
    .NavItem_NavItemAuth__DropItem_img__1c5A_ {
      margin-left: 1.5rem;
      display: block;
      width: 2.5rem;
      height: 2.5rem;
      -webkit-transition: all 300ms ease-out;
      transition: all 300ms ease-out;
      -webkit-filter: invert(0.15);
              filter: invert(0.15); }
    .NavItem_NavItemAuth__DropItem__C1eIy:hover .NavItem_NavItemAuth__DropItem_img__1c5A_ {
      -webkit-filter: invert(0.7);
              filter: invert(0.7); }

.NavItems_NavItems__I88Jl {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out; }
  @media only screen and (max-width: 50em) {
    .NavItems_NavItems__I88Jl {
      flex-direction: column;
      visibility: hidden;
      opacity: 0;
      position: fixed;
      bottom: 100%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); } }
  @media only screen and (max-width: 50em) {
    .NavItems_NavItems_open__3R2PI {
      visibility: visible;
      opacity: 1;
      -webkit-transform: translate(-50%, 50%);
              transform: translate(-50%, 50%);
      bottom: 50%; } }

.Navigation_Navigation__2nqXw {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  width: 100%;
  height: 8rem;
  background-color: #0f0f0f;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 500;
  -webkit-transition: -webkit-transform 500ms ease-out;
  transition: -webkit-transform 500ms ease-out;
  transition: transform 500ms ease-out;
  transition: transform 500ms ease-out, -webkit-transform 500ms ease-out; }
  @media only screen and (max-width: 50em) {
    .Navigation_Navigation__2nqXw {
      grid-template-rows: repeat(2, 1fr);
      height: 15rem; } }
  .Navigation_Navigation__Searchbar__1GQkg {
    justify-self: center;
    width: 100%; }
    @media only screen and (max-width: 50em) {
      .Navigation_Navigation__Searchbar__1GQkg {
        grid-row: 2 / 3;
        grid-column: 1 / -1;
        width: 80%; } }
  .Navigation_Navigation__NavItems__21s3a {
    justify-self: end;
    margin: 0 1rem; }
    @media only screen and (max-width: 50em) {
      .Navigation_Navigation__NavItems__21s3a {
        display: none; } }
  .Navigation_Navigation__Logo__3zx21 {
    display: block;
    margin: 0 1rem 0 3rem;
    justify-self: start;
    width: 10rem;
    margin-right: auto;
    cursor: pointer; }
    @media only screen and (max-width: 50em) {
      .Navigation_Navigation__Logo__3zx21 {
        grid-row: 1 / 2;
        grid-column: 1 / -1;
        margin-right: 0;
        margin-top: 1rem;
        width: 13rem;
        justify-self: center; } }

.Sidedrawer_Sidedrawer__3yJJm {
  display: none; }
  @media only screen and (max-width: 50em) {
    .Sidedrawer_Sidedrawer__3yJJm {
      position: fixed;
      top: 0;
      left: 0;
      display: block;
      width: 100vw;
      height: 100vh;
      z-index: 1000;
      visibility: hidden;
      opacity: 0;
      -webkit-transition: all 200ms ease-out;
      transition: all 200ms ease-out; }
      .Sidedrawer_Sidedrawer_open__2ibdW {
        visibility: visible;
        opacity: 1; } }

.FooterNavItem_FooterNavItem__umepH {
  list-style: none;
  display: block; }
  .FooterNavItem_FooterNavItem__Link__dL_oh {
    font-size: 3rem;
    text-decoration: none;
    color: inherit; }

.FooterNavItems_FooterNavItems__1AUBh {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; }
  .FooterNavItems_FooterNavItems__1AUBh > *:not(:last-child) {
    margin-right: 4rem; }

.FooterName_FooterName__TJNOz {
  font-size: 3rem; }

.Copyright_Copyright__2nZze {
  text-align: center;
  font-size: 1.6rem;
  padding: 1rem 0; }

.FooterCredits_FooterCredits__1bwTK {
  font-size: 1.4rem;
  padding: .2rem; }
  .FooterCredits_FooterCredits__1bwTK a {
    color: inherit;
    text-decoration: none;
    -webkit-transition: color 300ms ease-out;
    transition: color 300ms ease-out; }
    .FooterCredits_FooterCredits__1bwTK a:hover {
      color: #fff; }

.ExternalLogo_ExternalLogo__3oQfh {
  display: inline-block; }
  .ExternalLogo_ExternalLogo__Logo__3onhd {
    display: inline-block;
    width: 6rem;
    height: 6rem;
    margin: 3rem; }
    @media only screen and (max-width: 37.5em) {
      .ExternalLogo_ExternalLogo__Logo__3onhd {
        margin: 1rem 2rem; } }
    @media only screen and (max-width: 18.75em) {
      .ExternalLogo_ExternalLogo__Logo__3onhd {
        margin: 1rem 1rem; } }
  .ExternalLogo_ExternalLogo__Credit__3pp8C {
    display: inline-block;
    width: auto;
    height: 12rem;
    margin: 1rem; }
    @media only screen and (max-width: 37.5em) {
      .ExternalLogo_ExternalLogo__Credit__3pp8C {
        height: 9rem; } }
    @media only screen and (max-width: 25em) {
      .ExternalLogo_ExternalLogo__Credit__3pp8C {
        margin-bottom: -3rem; } }

.Button_Button__2WNJi {
  display: inline-block;
  border: none;
  padding: .5rem 2rem;
  margin-bottom: .5rem;
  font-size: 1.6rem;
  font-family: inherit;
  background-color: transparent;
  color: inherit;
  cursor: pointer; }
  .Button_Button__2WNJi:focus {
    outline: none; }

.Footer_Footer__2TiVi {
  position: fixed;
  bottom: 0;
  z-index: -1;
  width: 100%;
  display: block;
  padding: 10rem 0 7rem;
  background-image: -webkit-radial-gradient(30% 30%, circle, #131313 0%, #030303 65%);
  background-image: radial-gradient(circle at 30% 30%, #131313 0%, #030303 65%); }
  @media only screen and (max-width: 37.5em) {
    .Footer_Footer__2TiVi {
      padding: 10rem 0 5rem; } }
  @media only screen and (max-width: 18.75em) {
    .Footer_Footer__2TiVi {
      max-width: 100%; } }
  .Footer_Footer__Nav__-8z7K {
    display: flex;
    justify-content: center;
    align-items: center; }
    .Footer_Footer__Nav__-8z7K > *:last-child > * a {
      -webkit-transition: all 200ms ease-out;
      transition: all 200ms ease-out; }
    .Footer_Footer__Nav__-8z7K > *:last-child > * a:hover {
      color: #fff;
      -webkit-filter: drop-shadow(0 0.5rem 1rem rgba(255, 0, 153, 0.6));
              filter: drop-shadow(0 0.5rem 1rem rgba(255, 0, 153, 0.6)); }
    .Footer_Footer__Nav__-8z7K > *:not(:last-child) {
      margin-right: 4rem; }
    @media only screen and (max-width: 37.5em) {
      .Footer_Footer__Nav__-8z7K {
        flex-direction: column; }
        .Footer_Footer__Nav__-8z7K > *:first-child {
          margin: 0 auto; }
        .Footer_Footer__Nav__-8z7K > *:last-child {
          flex-direction: column;
          justify-content: center;
          align-items: center; }
          .Footer_Footer__Nav__-8z7K > *:last-child li {
            margin: 1.5rem auto; } }
  .Footer_Footer__External__3a5FG {
    display: flex;
    justify-content: center;
    align-items: center; }
    .Footer_Footer__External__3a5FG > * {
      -webkit-transition: all 200ms ease-out;
      transition: all 200ms ease-out; }
    .Footer_Footer__External__3a5FG > *:hover {
      -webkit-filter: brightness(2) drop-shadow(0 0.5rem 1rem rgba(255, 0, 153, 0.6));
              filter: brightness(2) drop-shadow(0 0.5rem 1rem rgba(255, 0, 153, 0.6)); }
    @media only screen and (max-width: 37.5em) {
      .Footer_Footer__External__3a5FG {
        position: absolute;
        right: 3rem;
        top: 10rem;
        flex-direction: column;
        align-items: flex-end; } }
    @media only screen and (max-width: 18.75em) {
      .Footer_Footer__External__3a5FG {
        position: relative;
        top: auto;
        top: initial;
        right: auto;
        right: initial;
        flex-direction: row;
        align-items: flex-end; } }
  .Footer_Footer__API__3NkYf {
    display: flex;
    align-items: center;
    justify-content: center; }
    .Footer_Footer__API__3NkYf > * {
      -webkit-transition: all 200ms ease-out;
      transition: all 200ms ease-out; }
    .Footer_Footer__API__3NkYf > *:hover {
      -webkit-filter: drop-shadow(-0.9rem 0.9rem 2.5rem rgba(255, 0, 153, 0.4));
              filter: drop-shadow(-0.9rem 0.9rem 2.5rem rgba(255, 0, 153, 0.4)); }
    @media only screen and (max-width: 37.5em) {
      .Footer_Footer__API__3NkYf {
        margin-top: 3rem; } }
    @media only screen and (max-width: 25em) {
      .Footer_Footer__API__3NkYf {
        flex-direction: column;
        margin: 0 auto;
        margin-top: 2rem;
        width: 50%; } }
  .Footer_Footer__Credits__1Mc_H {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 7.5rem;
    border-top: 1px solid #797474;
    border-bottom: 1px solid #797474; }
    @media only screen and (max-width: 37.5em) {
      .Footer_Footer__Credits__1Mc_H {
        margin-top: 3rem; } }
  .Footer_Footer__CreditsList__tnq9o {
    position: absolute;
    bottom: 100%;
    right: 0;
    padding: 1rem;
    background-color: #0f0f0f;
    border: 1px solid black;
    border-top-left-radius: 1rem;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: right;
            transform-origin: right;
    -webkit-transition: -webkit-transform 200ms ease-out;
    transition: -webkit-transform 200ms ease-out;
    transition: transform 200ms ease-out;
    transition: transform 200ms ease-out, -webkit-transform 200ms ease-out; }
    .Footer_Footer__CreditsList_show__1iwYz {
      -webkit-transform: scaleX(1);
              transform: scaleX(1); }
    .Footer_Footer__CreditsList_text__2nKPX {
      font-size: 2rem;
      font-weight: 600; }

.Message_Message__10atC {
  position: relative;
  display: block;
  padding: 2rem 10rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
  box-shadow: -1.25rem 1.5rem 3.5rem rgba(0, 0, 0, 0.3);
  width: 45vw; }
  @media only screen and (max-width: 50em) {
    .Message_Message__10atC {
      width: 75vw;
      padding: 2rem 5rem; } }
  @media only screen and (max-width: 31.25em) {
    .Message_Message__10atC {
      width: 85vw; } }
  .Message_Message__Text__2pe3V {
    font-size: 1.8rem;
    font-weight: 500;
    text-align: center;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin: 0 auto; }
    @media only screen and (max-width: 31.25em) {
      .Message_Message__Text__2pe3V {
        width: auto; } }
  .Message_Message_success__2jWNL {
    background-color: #69f0ae;
    color: #1b5e20; }
  .Message_Message_warning__1m61i {
    background-color: #fff59d;
    color: #f57f17; }
  .Message_Message_danger__h04as {
    background-color: #ef9a9a;
    color: #b71c1c; }
  .Message_Message__Close__3DFLa {
    display: block;
    width: 1.8rem;
    height: 1.8rem;
    position: absolute;
    top: 1.2rem;
    left: 1.3rem;
    -webkit-filter: invert(0.2);
            filter: invert(0.2);
    -webkit-transition: all 200ms ease-out;
    transition: all 200ms ease-out;
    cursor: pointer; }
    .Message_Message__Close__3DFLa:hover {
      -webkit-filter: invert(0.1);
              filter: invert(0.1);
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }

.Message_msg_appear__1ejr2 {
  opacity: 0;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out; }

.Message_msg_appear_active__35rXp {
  opacity: 1;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out; }

.Message_msg_enter_done__1wGi2 {
  opacity: 1;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out; }

.Message_msg_exit__37ZeU {
  opacity: 1;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out; }

.Message_msg_exit_active__2DJgV {
  opacity: 1;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out; }

.Message_msg_exit_done__2yRP1 {
  opacity: 0;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out; }

.Hamburger_Hamburger__n3O6k {
  width: 6rem;
  height: 3.5rem;
  position: fixed;
  top: 2rem;
  right: 3rem;
  z-index: 1000;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  display: none; }
  @media only screen and (max-width: 50em) {
    .Hamburger_Hamburger__n3O6k {
      display: block;
      grid-row: 1 / 2;
      grid-column: 2 / -1;
      justify-self: end;
      z-index: 10000; } }
  .Hamburger_Hamburger__n3O6k span {
    display: block;
    position: absolute;
    height: .8rem;
    width: 100%;
    background: #FF0099;
    border-radius: .9rem;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    transition: .25s ease-in-out; }
    .Hamburger_Hamburger__n3O6k span:nth-child(1) {
      top: 0px; }
    .Hamburger_Hamburger__n3O6k span:nth-child(2), .Hamburger_Hamburger__n3O6k span:nth-child(3) {
      top: 1.5rem; }
    .Hamburger_Hamburger__n3O6k span:nth-child(4) {
      top: 3rem; }
  .Hamburger_Hamburger_open__2_fBe span:nth-child(1) {
    top: 1.5rem;
    width: 0%;
    left: 50%; }
  .Hamburger_Hamburger_open__2_fBe span:nth-child(2) {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .Hamburger_Hamburger_open__2_fBe span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .Hamburger_Hamburger_open__2_fBe span:nth-child(4) {
    top: 1.5rem;
    width: 0%;
    left: 50%; }

.Layout_Layout__3RbB0 {
  background-color: #0f0f0f;
  position: relative; }
  .Layout_Layout__3RbB0::before {
    content: '';
    z-index: 1000;
    position: fixed;
    background-color: none;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease; }
  @media only screen and (max-width: 50em) {
    .Layout_Layout_open__2QO37::before {
      content: '';
      display: block;
      background-color: rgba(0, 0, 0, 0.9);
      z-index: 1000;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      visibility: visible;
      opacity: 1; } }
  .Layout_Layout__Main__1eo7X {
    position: relative;
    min-height: calc(100vh - 8rem);
    height: 100%;
    background-image: -webkit-linear-gradient(134deg, #25064c 0%, #020202 50%);
    background-image: linear-gradient(316deg, #25064c 0%, #020202 50%);
    margin: 0 auto 62rem; }
    @media only screen and (max-width: 37.5em) {
      .Layout_Layout__Main__1eo7X {
        margin: 0 auto 65rem; } }
    @media only screen and (max-width: 18.75em) {
      .Layout_Layout__Main__1eo7X {
        margin: 0 auto 73rem; } }
  .Layout_Layout__Message___kS1g {
    position: fixed;
    top: 5rem;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    z-index: 50000; }

.Backdrop_Backdrop__1P6xn {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  -webkit-transition: all 1500ms ease-out;
  transition: all 1500ms ease-out; }
  @media only screen and (max-width: 68.75em) {
    .Backdrop_Backdrop__1P6xn {
      display: none; } }
  .Backdrop_Backdrop_appear__9luc0 {
    opacity: 0; }
  .Backdrop_Backdrop_appear_active__3jvl4 {
    opacity: 1; }
  .Backdrop_Backdrop_enter_done__1bakq {
    opacity: 1; }

.Share_Share__jKPgm {
  display: block;
  position: fixed;
  top: 3%;
  right: 1rem;
  z-index: 1000; }
  @media only screen and (max-width: 68.75em) {
    .Share_Share__jKPgm {
      z-index: 20000; } }
  .Share_Share__Link__nSE9h {
    display: block;
    margin: .5rem 0; }
  .Share_Share__Icon__3lOVk {
    display: block;
    width: 4rem;
    height: 4rem; }
  .Share_Share__Group__3guTB {
    display: flex;
    flex-direction: column;
    -webkit-transform: translateX(150%);
            transform: translateX(150%);
    height: 0;
    -webkit-transition: all 200ms ease-out;
    transition: all 200ms ease-out;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
    .Share_Share__Group_open__3m5KE {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      height: 100%; }
  .Share_Share__Toggle__3fOpX {
    display: block;
    cursor: pointer;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    -webkit-transition: all 200ms ease-out;
    transition: all 200ms ease-out;
    -webkit-perspective: 20rem;
            perspective: 20rem;
    margin-bottom: 1rem; }
    @media only screen and (max-width: 68.75em) {
      .Share_Share__Toggle__3fOpX {
        background-color: #ccc;
        border-radius: 50%; }
        .Share_Share__Toggle__3fOpX > * {
          padding: .7rem;
          -webkit-filter: brightness(0);
                  filter: brightness(0); } }
    .Share_Share__Toggle_open__1rqAj {
      display: block;
      -webkit-transform: rotateY(0);
              transform: rotateY(0); }

.Modal_Modal__TF4cq {
  position: fixed;
  top: 3%;
  left: 5%;
  right: 5%;
  bottom: 3%;
  z-index: 10000;
  border: 2px solid black;
  border-radius: 1rem;
  overflow-y: scroll;
  background-color: #0f0f0f; }
  .Modal_Modal__TF4cq::-webkit-scrollbar {
    display: none; }
  @media only screen and (max-width: 68.75em) {
    .Modal_Modal__TF4cq {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: 0; } }

html {
  font-size: 62.5%; }
  @media only screen and (max-width: 81.25em) {
    html {
      font-size: 56.25%; } }
  @media only screen and (max-width: 62.5em) {
    html {
      font-size: 50%; } }
  @media only screen and (max-width: 50em) {
    html {
      font-size: 46.88%; } }

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  line-height: 1; }

body {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #bbb; }
  body::-webkit-scrollbar {
    display: none; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

