.Money {
  width: 100%;
  height: 100%;
  text-align: center;
  white-space: nowrap;
  text-shadow: 0 1rem 2rem rgba(0, 0, 0, .4);

  @media only screen and (max-width: 43.75em) {
    white-space: initial;
    margin: 0 3rem;
  }

  &__Title {
    font-size: 3rem;
    font-weight: 300;
  }

  &__Img {
    display: block;
    height: 13rem;
    width: 13rem;
    margin: 1.5rem auto;
    filter: drop-shadow(0 .5rem 3rem rgba(0,0,0,.5));
  }
}