@import '../../../../styles/variables';

.VideoList {
  overflow-x: hidden;
  background-color: rgba(0, 0, 0, .8);
  color: $primary-light-gray;
  border-bottom: 3px solid black;
  border-left: 3px solid black;
  width: 100%;
  height: 100%;

  @media only screen and (max-width: 68.75em) {
    display: grid;
    grid-template-columns: repeat(auto-fit, 25rem);
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: 37.5em) {
    overflow-y: auto;
    height: 50%;
  }
 
  &__Item {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    transition: all 150ms ease-in;
    padding: .75rem 1.25rem;

    @media only screen and (max-width: 68.75em) {
      flex-direction: column;
      height: auto;
      width: auto;
    }
    
    &_active {
      cursor: initial;
      background-color: $primary-gray;
      color: rgba(0, 0, 0, .8);
    }

    &:hover:not(&_active) {
      background-color: rgba(255, 255, 255, .2);
    }
  }

  &__Img {
    position: relative;
    height: 100%;
    width: 100%;
    
    @media only screen and (max-width: 68.75em) {
      width: 75%;
      height: auto;
    }
  }

  &__Overlay {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(to bottom right, 
        rgba(0, 0, 0, 0) 5%, 
        rgba(0, 0, 0, .55) 95%);
    }
  }

  &__State {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  &__Type {
    display: inline-block;
    height: 100%;
    width: 100%;
    text-align: center;
  }
}