@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

html {
  font-size: 62.5%;

  @media only screen and (max-width: 81.25em) {
      font-size: 56.25%;  // 1 rem = 9 px
  }
  
  @media only screen and (max-width: 62.5em) {
      font-size: 50%;  // 1 rem = 8 px
  }

  @media only screen and (max-width: 50em) {
      font-size: 46.88%;  // 1 rem = 7 px
  }
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  line-height: 1;
}

body {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #bbb;

  &::-webkit-scrollbar {
    display: none;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}