@import '../../../../styles/variables';

.Input {
  outline: none;
  font: inherit;
  display: inline-block;
  border: none;
  height: 100%;
  width: 100%;

  &:focus {
    outline: none;
  }

  &__DiscoverText {
    display: block;
    grid-column: -1 / 1;
    width: 100%;
    padding: $filter-padding;
    font-family: inherit;
    font-size: $normal-font-size;
    background-color: $nav-background-color;
    border-radius: $filter-border-radius;
    box-shadow: $filter-box-shadow;
    transition: all 200ms ease-out;
    color: $filter-font-color;

    &:focus {
      border-radius: 0;
      border-top-left-radius: $filter-border-radius-top-side;
      border-top-right-radius: $filter-border-radius-top-side;
      background-color: $primary-gray;
      color: $primary-dark-gray;

      &::placeholder {
        color: $primary-dark-gray;
      }
    }
    
    &::placeholder {
      color: $filter-font-color;
    }
  }

  &__DiscoverSubmit {
    display: inline-block;
    font-size: 1.8rem;
    font-weight: 600;
    width: 15rem;
    height: 4rem;
    margin-top: 4rem;
    padding: 1rem 2rem;
    border: 1px solid black;
    border-radius: $filter-border-radius;
    justify-self: center;
    grid-column: 1 / -1;
    box-shadow: $filter-box-shadow;
    background-color: $primary-dark-gray;
    color: inherit;
    cursor: pointer;
    transition: all 200ms ease-out;

    &:hover {
      transform: translateY(-.15rem);
      box-shadow: 0 2rem 2rem rgba(0, 0, 0, .5);
    }

    &:active {
      transform: translateY(.05rem);
      box-shadow: 0 1rem 1rem rgba(0, 0, 0, .7);
    }
  }

  &__SearchbarText {
    width: 70%;
    height: 120%;
    border-radius: $large-border-radius;
    font-size: $normal-font-size;
    padding: 0 2rem;
    transition: all 400ms ease-in-out;
    background-color: $primary-light-gray;
    backface-visibility: hidden;

    &::placeholder {
      color: rgba($primary-dark-gray, .7);
    }

    &:focus {
      width: 90%;
      box-shadow: 0 .1rem 0.15rem $primary-purple-light,
                  0 -.1rem 0.15rem $primary-purple-light,
                  .1rem .1rem 0.15rem $primary-purple-light,
                  .1rem -.1rem 0.15rem $primary-purple-light,
                  -.1rem .1rem 0.15rem $primary-purple-light,
                  -.1rem -.1rem 0.15rem $primary-purple-light;
      @media only screen and (max-width: 75em) {
        width: 100%;
      }

      @media only screen and (max-width: 68.75em) {
        width: 100%;
      }
    }

    @media only screen and (max-width: 75em) {
      width: 95%;
    }

    @media only screen and (max-width: 68.75em) {
      width: 90%;
    }
  }
  
  &__SearchbarSubmit {
    width: 7rem;
    height: 120%;
    margin-left: -6.75rem;
    padding: .6rem 0;
    border-radius: 10rem;
    backface-visibility: hidden;
    background-color: $primary-purple-light;
    transition: all 400ms ease-in-out;
    cursor: pointer;    

    &:hover {
      transform: scale(1.1);
      animation: pulse 2s infinite;
    }
  }
}


@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba($primary-light-gray, .45);
  }
  70% {
    box-shadow: 0 0 0 10px rgba($primary-light-gray, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba($primary-light-gray, 0);
  }
}