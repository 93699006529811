@import '../../styles/variables';

.Movies {
  &__Body {
    padding: 5rem 0;
    margin: 0 auto;
    width: $max-width;
    max-width: 90%;

    @media only screen and (max-width: 37.5em) {
      width: 100%;
      max-width: 100%;
    }
  }
}