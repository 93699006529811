@import '../../../styles/variables';

.Footer {
  position: fixed;
  bottom: 0;
  z-index: -1;
  width: 100%;
  display: block;
  padding: 10rem 0 7rem;
  background-image: $footer-gradient;

  @media only screen and (max-width: 37.5em) {
    padding: 10rem 0 5rem;   
  }

  @media only screen and (max-width: 18.75em) {
    max-width: 100%;
  }

  &__Nav {
    display: flex;
    justify-content: center;
    align-items: center;

    & > *:last-child > * a {
      transition: all 200ms ease-out;
    }

    & > *:last-child > * a:hover {
      color: #fff;
      filter: drop-shadow(0 .5rem 1rem rgba($primary-pink, .6));
    }

    & > *:not(:last-child) {
      margin-right: 4rem;
    }

    @media only screen and (max-width: 37.5em) {
      flex-direction: column;

      & > *:first-child {
        margin: 0 auto;
      }

      & > *:last-child {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        li {
          margin: 1.5rem auto;
        }
      }
    }
  }

  &__External {
    display: flex;
    justify-content: center;
    align-items: center;
    
    & > * {
      transition: all 200ms ease-out;
    }

    & > *:hover {
      filter: brightness(2) drop-shadow(0 .5rem 1rem rgba($primary-pink, .6));
    }

    @media only screen and (max-width: 37.5em) {
      position: absolute;
      right: 3rem;
      top: 10rem;
      flex-direction: column;
      align-items: flex-end;  
    }

    @media only screen and (max-width: 18.75em) {
      position: relative;
      top: initial;
      right: initial;
      flex-direction: row;
      align-items: flex-end;  
    }
  }

  &__API {
    display: flex;
    align-items: center;
    justify-content: center;

    & > * {
      transition: all 200ms ease-out;
    }

    & > *:hover {
      filter: drop-shadow(-.9rem .9rem 2.5rem rgba($primary-pink, .4));
    }

    @media only screen and (max-width: 37.5em) {
      margin-top: 3rem; 
    }

    @media only screen and (max-width: 25em) {
      flex-direction: column;
      margin: 0 auto;
      margin-top: 2rem;
      width: 50%;
    }
  }

  &__Credits {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    margin-top: 7.5rem;
    border-top: 1px solid $primary-gray;
    border-bottom: 1px solid $primary-gray;

    @media only screen and (max-width: 37.5em) {
      margin-top: 3rem;       
    }
  }

  &__CreditsList {
    position: absolute;
    bottom: 100%;
    right: 0;
    padding: 1rem;
    background-color: $primary-dark-gray;
    border: 1px solid black;
    border-top-left-radius: 1rem;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 200ms ease-out;

    &_show {
      transform: scaleX(1);
    }

    &_text {
      font-size: 2rem;
      font-weight: 600;
    }
  }
}