.DiscoverBody {
  position: relative;
  padding-bottom: 5rem;
  margin-bottom: 15rem;

  &__List {
    height: 100%;

    @media only screen and (max-width: 56.25em) {
      width: 90%;
      margin: 0 auto;
    }
    
    &_hide {
      display: none;
    }
  }

  &__Arrows {
    position: absolute;
    top: 42.5%;
    transform: translateY(-50%);
    z-index: 10;
    transition: all 200ms ease-out;   
    
    &_left { 
      left: -4%; 

      @media only screen and (max-width: 75em) {
        left: -3%;
      }
      
      @media only screen and (max-width: 50em) {
        top: initial;
        bottom: -5rem;
        left: 40%;
      }

      @media only screen and (max-width: 37.5em) {
        left: 30%;
      }
      
      &:hover {
        transform: translate(-.25rem, -50%);
        filter: drop-shadow(.75rem .75rem .7rem rgba(0,0,0,.5)) brightness(1.5);
      }
    }

    &_right{
      right: -5%; 

      @media only screen and (max-width: 75em) {
        right: -3%;
      }
      
      @media only screen and (max-width: 50em) {
        top: initial;
        bottom: -5rem;
        right: 40%;
      }

      @media only screen and (max-width: 37.5em) {
        right: 30%;
      }

      &:hover {
        transform: translate(.25rem, -50%);
        filter: drop-shadow(-.75rem .75rem .7rem rgba(0,0,0,.5)) brightness(1.5);
      }
    } 
  }
}