.Hearts {
  display: flex;

  & > *:not(:last-child) {
    margin-right: .2rem;
  }

  &__Carousel {    
    display: block;
    width: 2rem;
    height: 2rem;
  }

  &__Thumbnail {    
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  &__Work {    
    display: block;
    width: 1.75rem;
    height: 1.75rem;
    margin-bottom: .5rem;
  }

  &__RateFilm {
    display: block;
    width: 2rem;
    height: 2rem;
  }
}