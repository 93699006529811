@import '../../../../styles/variables';

.VideoSummary {
  color: inherit;
  padding: 1rem;    
  border-bottom: 3px solid black;
  border-left: 3px solid black;
  width: 100%;

  @media only screen and (max-width: 68.75em) {
    width: 140%;
    overflow-y: auto;
    padding-right: 3rem;
  }

  @media only screen and (max-width: 37.5em) {
    width: 100%;
  }

  &__RateFav {
    display: flex;
    justify-content: space-between;
    align-items: center; 
  }

  &__DescList {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;

    @media only screen and (max-width: 43.75em) {
      flex-direction: column;
      
      & > *:first-child {
        margin-bottom: 1rem;
      }
    }
  }
  
  &__DescItem {
    display: flex;
    flex-direction: column;
    
  }

  &__DescTitle {
    font-size: 1.6rem;
    @media only screen and (max-width: 68.75em) {
      font-size: 1.8rem;
    }
  }

  &__DescText {
    font-size: 1.4rem;
    @media only screen and (max-width: 68.75em) {
      font-size: 1.8rem;
    }
  }

  &__Overview {
    font-size: 1.4rem;
    @media only screen and (max-width: 68.75em) {
      font-size: 1.8rem;
    }
  }

  &__Genres {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.4rem;

    @media only screen and (max-width: 68.75em) {
      font-size: 2rem;
    }

    & > *:not(:last-child) {
      margin-right: 1rem;
      border-right: 1px solid $primary-gray;
      padding-right: 1rem;
    }
  }
}