@import '../../../../styles/variables';

.SpinnerWrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000;
  transition: all 1500ms ease-in-out 500ms;
  white-space: nowrap;

  @media only screen and (max-width: 37.5em) {
    white-space: initial;
    text-align: center;
  }
  
  &_enter {
    background-color: $primary-dark-gray;
    background-image: $footer-gradient;
  }  
  
  &_exit_active {
    background-color: $primary-purple;
    background-image: $footer-gradient;
    opacity: 0;    
  }
  
  &_exit_done {
    background-image: $footer-gradient;
    opacity: 0;
  }
}

.SpinnerText {
  &__Title {
    font-weight: 300;
    font-size: 6.5rem;
    letter-spacing: 1.5rem;
    text-transform: uppercase;
    display: inline-block;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  &__Subtitle {
    font-weight: 300;
    font-size: 5rem;
    letter-spacing: 1rem;
    text-transform: uppercase;
    display: inline-block;
    position: absolute;
    bottom: 32%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.Spinner {
  margin: 2rem auto;
  width: 10rem;
  height: 10rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: rotateZ(45deg) translate(-70.71% , 0);
  transform-origin: center center;

  &__Cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    -ms-transform: scale(1.1);
    transform: scale(1.1); 
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $primary-purple-light;
      animation: sk-foldCubeAngle 2.4s infinite linear both;
      -ms-transform-origin: 100% 100%;
      transform-origin: 100% 100%;
    }

    &_2 {
      transform: scale(1.1) rotateZ(90deg);
      &::before { animation-delay: 0.3s; }
    }

    &_3 {
      transform: scale(1.1) rotateZ(180deg);
      &::before { animation-delay: 0.6s; }
    }

    &_4 {
      transform: scale(1.1) rotateZ(270deg);
      &::before { animation-delay: 0.9s; }
    }
  }
}

@keyframes sk-foldCubeAngle {
  0%, 10% {
    transform: perspective(14rem) rotateX(-180deg);
    opacity: 0; 
  } 25%, 75% {
    transform: perspective(14rem) rotateX(0deg);
    opacity: 1; 
  } 90%, 100% {
    transform: perspective(14rem) rotateY(180deg);
    opacity: 0; 
  }
}