.ExternalLogo {
  display: inline-block;
  
  &__Logo {
    display: inline-block;
    width: 6rem;
    height: 6rem;
    margin: 3rem;

    @media only screen and (max-width: 37.5em) {
      margin: 1rem 2rem; 
    }
    
    @media only screen and (max-width: 18.75em) {
      margin: 1rem 1rem;
    }
  }
  
  &__Credit {
    display: inline-block;
    width: auto;
    height: 12rem;
    margin: 1rem;

    @media only screen and (max-width: 37.5em) {
      height: 9rem; 
    }

    @media only screen and (max-width: 25em) {
      margin-bottom: -3rem;
    }    
  }
}