@import '../../../../styles/variables';

.Body {
  max-width: 80%;
  margin: 0 auto;

  &__NotAvailable {
    display: block;
    font-size: 3rem;
    text-align: center;
    text-transform: uppercase;
    padding: 10rem 0;
  }

  &__Staff {
    width: 85%;
    margin: 7rem auto;
  }

  &__Reviews {
    display: block;
    width: 100%;  
    margin: 7rem auto;
  }

  &__Money {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    max-width: $max-width;
    margin: 7rem auto;
    padding: 5rem 10rem;
  }
}