.Backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0, .8);
  z-index: 999;
  transition: all 1500ms ease-out;

  @media only screen and (max-width: 68.75em) {
    display: none;
  }
  
  &_appear {
    opacity: 0;
  }
  
  &_appear_active {
    opacity: 1;
  }
  
  &_enter_done {
    opacity: 1;
  }
}