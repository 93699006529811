@import '../../../../styles/variables';

.SpinnerSecondaryWrapper {
  height: $filmList-min-height;
  position: relative;
}

.SpinnerSecondary {
  margin: 100px auto;
  width: 8rem;
  height: 8rem;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  &__Child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    &::before {
      content: '';
      display: block;
      margin: 0 auto;
      width: 15%;
      height: 15%;
      background-color: $primary-light-gray;
      border-radius: 100%;
      animation: SpinnerSecondaryBounceDelay 1.2s infinite ease-in-out both;
    }
  }

  &_2 {
    transform: rotate(30deg);
    &::before { animation-delay: -1.1s; }
  }

  &_3 {    
    transform: rotate(60deg); 
    &::before { animation-delay: -1s; }
  }

  &_4 {
    transform: rotate(90deg);
    &::before { animation-delay: -0.9s; }
  }

  &_5 {
    transform: rotate(120deg);
    &::before { animation-delay: -0.8s; }
  }

  &_6 {
    transform: rotate(150deg);
    &::before { animation-delay: -0.7s; }
  }

  &_7 {
    transform: rotate(180deg);
    &::before { animation-delay: -0.6s; }
  }

  &_8 {
    transform: rotate(210deg);
    &::before { animation-delay: -0.5s; }
  }

  &_9 {
    transform: rotate(240deg);
    &::before { animation-delay: -0.4s; }
  }

  &_10 {
    transform: rotate(270deg);
    &::before { animation-delay: -0.3s; }
  }

  &_11 {
    transform: rotate(300deg);
    &::before { animation-delay: -0.2s; }
  }

  &_12 {
    transform: rotate(330deg);
    &::before { animation-delay: -0.1s; }
  }
}

@keyframes SpinnerSecondaryBounceDelay {
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
    transform: scale(1);
  }
}