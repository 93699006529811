@import '../../styles/variables';

.Modal {
  position: fixed;
  top: 3%;
  left: 5%;
  right: 5%;
  bottom: 3%;
  z-index: 10000;
  border: 2px solid black;
  border-radius: 1rem;
  overflow-y: scroll;
  background-color: $nav-background-color;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 68.75em) {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 0;
  }
}