.Rating {
  &__Carousel {
    font-size: 2.25rem;
    font-weight: 300;
  }

  &__Thumbnail {
    font-size: 1.5rem;
    font-weight: 500;
    color: #fff;
  }
}